// @ts-ignore
import { EmitterSubscription, EventEmitter } from 'fbemitter'
import { onBeforeUnmount } from 'vue'
import { EventType } from '@/plugins/event/types'

const _EventEmitter = new EventEmitter()

export const useEventService = () => {
  const publishEvent = (type: EventType|string, ...data: any|Function) => {
    _EventEmitter.emit(type, ...data)
  }

  const addEventSubscription = (type: EventType|string, func: Function, autoRemove: boolean = true) => {
    const subscription: EmitterSubscription = _EventEmitter.addListener(type, func)

    if (autoRemove) {
      // noinspection TypeScriptUnresolvedFunction
      onBeforeUnmount(() => subscription.remove())
    }

    return subscription
  }

  const clearEventSubscriptions = (type: EventType) => {
    _EventEmitter.removeAllListeners(type)
  }

  return {
    publishEvent,
    addEventSubscription,
    clearEventSubscriptions
  }
}
