<template>
  <div class="flex h-full justify-center items-center">
    <div class="text-center">
      <div class="text-2xl md:text-4xl mb-4">404 - {{ $t('unknown-page') }}</div>
      <router-link class="text-xl md:text-2xl underline text-finsta-interact" to="/">{{ $t('click-here-to-go-to-frontpage') }}</router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NotFound'
})
</script>
