import { TenantRole } from '@/plugins/firebase/types'
import { Privilege, UserRole } from '@/types'
import ArrayUtils from '@/utilities/ArrayUtils'
import EnumHelper from '@/utilities/EnumHelper'

export const getAdminRoles = () => [TenantRole.tenantAdmin, TenantRole.admin]
export const getManagerRoles = () => [TenantRole.finstaManager, TenantRole.salaryManager, TenantRole.owner]
export const getObserverRoles = () => [TenantRole.tenantObserver, TenantRole.support, TenantRole.developer]

export default class RoleUtils {

  static getAllPrivileges = () => {
    return [Privilege.administrate, Privilege.observe]
  }

  static parseRoles = (data: TenantRole[]) => {
    const result: TenantRole[] = []
    const entries = EnumHelper.getNamesAndValues(TenantRole)
    data.forEach((e) => {
      const item = entries.find((en) => en.value === e)
      if (item) {
        result.push(e)
      }
    })
    return result
  }

  static mapRolesToPrivileges = (roles: TenantRole[]): Privilege[] => {
    const privileges: Privilege[] = []
    if (ArrayUtils.intersection(getObserverRoles(), roles).length > 0) {
      privileges.push(Privilege.observe)
    }
    if (ArrayUtils.intersection(getAdminRoles(), roles).length > 0) {
      privileges.push(Privilege.administrate)
    }
    return privileges
  }

  static hasPrivileges = (roles: TenantRole[]) => {
    return RoleUtils.mapRolesToPrivileges(roles).length > 0
  }

  static getActivePrivilege = (privileges: Privilege[]) => {
    if (privileges.length === 0) {
      return undefined
    }
    if (privileges.includes(Privilege.administrate)) {
      return Privilege.administrate
    }
    if (privileges.includes(Privilege.observe)) {
      return Privilege.observe
    }
    return undefined
  }

  static filterUserRoles = (roles?: UserRole[]) => {
    if (!roles) {
      return undefined
    }
    const result: UserRole[] = []
    roles.forEach((r) => {
      const ur = EnumHelper.parse(UserRole, r)
      if (ur) {
        result.push(ur)
      }
    })
    return result
  }
}
