import { FirebaseOptions } from 'firebase/app'
import { resolveConfigApiPath } from '@/services/ServerBasePathResolver'

export default (): Promise<FirebaseOptions> => {
  return new Promise((resolve) => {
    const apiUrl = resolveConfigApiPath()
    const promise = fetch(apiUrl)
      .then(value => value.json())
    resolve(promise)
  })
}
