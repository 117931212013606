import { Organization } from '@/generated/finsta-client-ts'
import { FirestoreOrg, TenantRole } from '@/plugins/firebase/types'
import { ExtendedUser } from '@/types'
import { ParsedToken } from 'firebase/auth'
import { ref, computed } from 'vue'

const findTenantIdFromClaims = (claims: ParsedToken): string | undefined => {
  let tid
  if (claims.mgr) {
    tid = String(claims.mgr)
  } else if (claims.tritt) {
    Object.entries(claims.tritt).find(([key, value]) => {
      if (key === 'tid') {
        tid = String(value)
      }
      return undefined
    })
  }
  return tid
}

const user = ref<ExtendedUser>()
const uid = ref<string>()
const tenantId = ref<string>()
const accessToken = ref<string>()

const activeTenant = ref<FirestoreOrg>()
const finstaOrg = ref<Organization>()

// TODO - Kristian: Consider using getRoles from FirebaseAuth instead?
const getUserRoles = (): TenantRole[] => {
  const claims = user.value?.claims
  if (!claims) {
    return []
  }
  return claims.tritt?.rls || []
}

const hasAdminPrivileges = computed(() =>
  getUserRoles().some(r => r === TenantRole.admin || r === TenantRole.support || r === TenantRole.developer)
)

const getTenantId = () => tenantId.value
const getOrgNo = () => tenantId.value ? tenantId.value?.substr(1, 9) : undefined
const getAccessToken = () => accessToken.value

const setUser = (extendedUser: ExtendedUser | undefined | null) => {
  if (!extendedUser) {
    user.value = undefined
    uid.value = undefined
    tenantId.value = undefined
    accessToken.value = undefined
    activeTenant.value = undefined

    return
  }

  user.value = extendedUser
  uid.value = extendedUser.uid
  tenantId.value = findTenantIdFromClaims(extendedUser.claims)
  accessToken.value = extendedUser.accessToken
}

const setActiveTenant = (at: FirestoreOrg | undefined) => {
  activeTenant.value = at
}

const setFinstaOrg = (org: Organization | undefined) => finstaOrg.value = org

const tid = computed(() => {
  return activeTenant.value?.id
})

const useAuthState = () => {
  return {
    activeTenant,
    setActiveTenant,

    setUser,
    user,
    uid,
    getAccessToken,

    finstaOrg,
    setFinstaOrg,

    getUserRoles,
    hasAdminPrivileges,
    getTenantId,
    getOrgNo,

    tid,
    authenticated: !!user.value
  }
}

export default useAuthState
