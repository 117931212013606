<template>
  <div id="splash-screen" class="bg-conta-green absolute inset-0 flex flex-col justify-center">
    <div class="top h-8" />
    <div class="center flex flex-col flex-grow items-center justify-center">
      <div class="bg-white rounded-lg w-28 h-28 flex items-center justify-center animate-pulse">
        <div class="i-custom:conta-initials text-conta-green text-[5rem]" />
      </div>
    </div>
    <div class="bottom flex items-center font-bold text-xl justify-center h-8">
      {{ $t('app.name') }}
    </div>
  </div>
</template>

<script setup lang="ts">

</script>

<script lang="ts">
export default { name: 'SplashScreen' }
</script>
