export enum EventType {
  AUTH_READY = 'auth-ready',
  APPLICATION_READY = 'application-ready',
  EXPAND_THEMES = 'expand-themes',
  COLLAPSE_THEMES = 'collapse-themes',
  TOGGLE_SHOW_EMPTY = 'toggle-show-empty',
  SYNCHRONIZE = 'synchronize',
  SYNCHRONIZATION_CAUSE_FINREG = 'synchronization-cause-finreg',
  SHOW_BEFORE_DELETE_WARNING_MODAL = 'show-before-delete-warning-modal',
  SHOW_UNSAVED_CHANGES_WARNING_MODAL = 'show-unsaved-changes-warning-modal',
  SHOW_EDIT_BALANCE_GROUP_MODAL = 'show-edit-balance-group-modal',
  SHOW_NEW_BALANCE_GROUP_MODAL = 'show-new-balance-group-modal',
  SHOW_EDIT_PROFIT_AND_LOSS_MODAL = 'show-edit-profit-and-loss-modal',
  SHOW_NEW_PROFIT_AND_LOSS_MODAL = 'show-new-profit-and-loss-modal',
  UPDATE_MENU_ITEMS = 'update-menu-items',
  SAVE_SLIDE_IN = 'save-slide-in',
  SHOW_DETAILS_FINREG_TRANSACTION_MODAL = 'show-details-finreg-transaction-modal',
  SHOW_PDF_MODAL = 'pdf-modal',
  OPEN_STOCK = 'open-stock',
  OPEN_STOCK_AS_ACTION = 'open-stock-action-slide-in',
  OPEN_STOCK_BY_UUID = 'open-stock-by-uuid',
  DELETE_STOCK_BY_UUID = 'delete-stock-by-uuid',
  OPEN_SUPPORT = 'open-support',
  SHOW_ADDITIONAL_TX_SLIDE_IN = 'show-additional-transaction-slide-in',
  SHOW_ADDITIONAL_TX_ACTION_SLIDE_IN = 'show-additional-transaction-action-slide-in',
  SHOW_SEARCH_SLIDE_IN = 'show-search-slide-in',
  SHOW_NEW_VERSION_MODAL = 'show-new-version-modal',
  OPEN_FIXED_ASSET_AS_ACTION = 'open-fixed-asset-action-slide-in',
  OPEN_FIXED_ASSET = 'open-fixed-asset-slide-in',
  CLOSE_SIDE_CONTROL_SLIDE_IN = 'close-side-control-slide-in',
  SCROLL_TO_ELEMENT_BY_ID = 'scroll-to-element-by-id',
  SHOW_EDIT_COMPANY_MODAL = 'show-edit-company-modal',
  SHOW_NEW_COMPANY_MODAL = 'show-new-company-modal',
  OPEN_CONTRIBUTION = 'open-contribution',
  SHOW_ADMIN_SLIDE_IN = 'show-admin-slide-in',
  OPEN_ACCOUNT = 'open-account',
  SHOW_2023_INFO_MODAL = 'show-2023-info-modal',
  NAVIGATE = 'navigate',
  OPEN_COMMARD_BAR = 'open-command-bar',
  OPEN_SHORTCUTS = 'open-shortcuts',
  LOGIN_TO_ID_PORTEN = 'login-to-id-porten'
}
