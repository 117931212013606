import client
  from '@/services/patron/PatronApi'
import useAuthState
  from '@/store/UseAuthState'
import {
  PatronOverview,
  ProductKey,
  ProvisionedService,
  ProvisioningStatus,
  PurchasedProduct,
  Service
} from '@tritt/patron-client-ts'
import {
  ref
} from 'vue'

const patronOverview = ref<PatronOverview>()

const getTenantId = () => {
  return patronOverview.value?.tenantId
}

const fetchPatronOverview = async (tenantId: string) => {
  try {
    const response: PatronOverview | undefined = await client.findPatronOverview({ tenantId }).toPromise()
    patronOverview.value = response
    return response
  } catch (e: any) {
    console.error('Fetch Patron Overview Error: ', e)
    return undefined
  }
}

const updatePatronOverview = async () => {
  const tenantId = useAuthState().getTenantId()

  if (!tenantId) {
    patronOverview.value = undefined
    return undefined
  }

  const result = await fetchPatronOverview(tenantId)
  return result
}

const hasSubscription = (): boolean => {
  return hasFinstaSubscription(patronOverview.value)
}

const hasFinstaSubscription = (patronOverview?: PatronOverview) => {
  if (!patronOverview) {
    return false
  }

  const purchasedProducts = patronOverview?.subscription?.purchasedProducts || []

  if (purchasedProducts.length === 0) {
    return false
  }

  const finstaSubscription: PurchasedProduct | undefined = purchasedProducts
    .filter((s: PurchasedProduct) => s.productKey === ProductKey.FinancialStatement)
    .find((s: PurchasedProduct) => {
      const now = new Date().setHours(0, 0, 0, 0)

      const startDate = new Date(s.startDate).setHours(0, 0, 0, 0)
      const expirationDate = new Date(s.expirationDate).setHours(0, 0, 0, 0)

      return startDate <= now && now <= expirationDate
    })

  return !!finstaSubscription
}

const hasProvision = (): boolean => {
  return hasFinstaProvision(patronOverview.value)
}

const hasFinstaProvision = (patronOverview?: PatronOverview) => {
  if (!patronOverview) {
    return false
  }

  const services = patronOverview.services || []
  if (services.length === 0) {
    return false
  }

  const finstaService: ProvisionedService | undefined = services
    .filter((s: ProvisionedService) => s.service === Service.Finsta)
    .find((s: ProvisionedService) => s.provisioningStatus === ProvisioningStatus.Success)

  return !!finstaService
}

const usePatronService = () => {
  return {
    patronOverview,

    updatePatronOverview,

    hasProvision,
    hasSubscription,

    getTenantId
  }
}

export default usePatronService
