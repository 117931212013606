import { useToast } from '@/plugins/toast'

const showLogs = false

export const syncToStorage = (key: string, value: any) => {
  if (showLogs) {
    console.log(`Sync to storage: [${key}:${value}]`)
  }

  if (typeof value === 'string') {
    localStorage.setItem(key, value)
  } else {
    localStorage.setItem(key, value.toString())
  }
}

export const getFromStorage = (key: string): string | null => {
  const value = localStorage.getItem(key)

  if (showLogs) {
    console.log(`Get from storage [${key}:${value}]`)
  }

  return value
}

export const clearStorage = () => {
  useToast().showSuccess({
    title: 'LocalStorage',
    message: 'Slettet alle innslag i LocalStorage. Last siden på nytt for å se effekten av slettingen.',
    duration: 3000
  })

  return localStorage.clear()
}
