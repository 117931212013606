import { useFirebaseAuth, useFirebaseFirestore, useFirebaseFunctions, useFirebaseWatch } from '@/plugins/firebase'
import { TenantRole } from '@/plugins/firebase/types'
import { allFinancialStatementsRoute, organizationsRoute } from '@/router/paths'
import { findOrganization } from '@/services/organization/OrganizationService'
import usePatronService from '@/services/patron/PatronService'
import useAuthState from '@/store/UseAuthState'
import { Privilege, UpdatePrivilegesCommand } from '@/types'
import RoleUtils from '@/utilities/authorization/RoleUtils'
import { until } from '@vueuse/core'

export const getActiveTenant = (orgId: string | undefined, userId: string | undefined) => {
  if (!orgId || !userId) {
    console.warn(`Cannot get active tenant using orgId [${orgId}] and userId [${userId}]`)
    return undefined
  }
  return useFirebaseFirestore().getOrgByOrgId(userId, orgId)
}

export const selectNextTenant = async () => {
  const tenantId = useAuthState().getTenantId()
  const userId = useAuthState().uid.value || ''
  const roles = await useFirebaseAuth().getRoles()

  if (!tenantId || roles.length === 0) {
    return
  }

  if (hasPrivileges(roles)) {
    await dropPrivilegesOverTenant({ tenantId: tenantId, privileges: [Privilege.observe, Privilege.administrate] })
  }
  const userOrgs = await useFirebaseFirestore().getOrgs(userId)
  const nonPrivilegedTenants = userOrgs.filter((tenant) => {
    return tenant.organizationId !== tenantId
  })

  if (nonPrivilegedTenants?.length === 1) {
    await setActiveTenant(nonPrivilegedTenants[0].organizationId)
    location.replace(allFinancialStatementsRoute.route({ tenantId: useAuthState().getTenantId()! }))
  } else {
    await clearActiveTenant()
    location.replace(organizationsRoute.route())
  }
}

export const setActiveTenant = async (tenantId: string) => {
  const result = await useFirebaseFunctions().setActiveTenant(tenantId)

  await useFirebaseAuth().refreshToken()
  await until(useAuthState().getTenantId).toBe(tenantId)
  await until(usePatronService().getTenantId).toBe(tenantId)

  if (usePatronService().hasProvision()) {
    useAuthState().setFinstaOrg(
      await findOrganization(useAuthState().getOrgNo()!)
    )
  }

  return result
}

// TODO - Kristian: Make sure more than just the tenantId is reset?
export const clearActiveTenant = async () => {
  const existingTenantId = String(useAuthState().getTenantId())

  const result = await useFirebaseFunctions().clearActiveTenant()

  await useFirebaseAuth().refreshToken()
  await until(useAuthState().getTenantId).not.toBe(existingTenantId)

  return result
}

export const gainPrivilegesOverTenant = async (command: UpdatePrivilegesCommand) => {
  await useFirebaseFunctions().gainPrivilegesOverTenant(command)
  await setActiveTenant(command.tenantId)
  await useFirebaseAuth().refreshToken()
  await until(useAuthState().getTenantId).toBe(command.tenantId)

  window.location.href = window.location.origin + allFinancialStatementsRoute.route({ tenantId: command.tenantId })
}

export const gainPrivilegesOverTenant2 = async (command: UpdatePrivilegesCommand) => {
  let success = true

  try {
    await useFirebaseFunctions().gainPrivilegesOverTenant(command)
    await setActiveTenant(command.tenantId)
  } catch (error: any) {
    console.error('Error gaining privileges over tenant: ', command.tenantId)
    success = false
  }

  return success
}

export const dropPrivilegesOverTenant2 = async (isActive: boolean, command: UpdatePrivilegesCommand) => {
  if (isActive) {
    await selectNextTenant()
  } else {
    await useFirebaseFunctions().dropPrivilegesOverTenant(command)
  }
}

export const dropPrivilegesOverTenant = async (command: UpdatePrivilegesCommand) => {
  await useFirebaseFunctions().dropPrivilegesOverTenant(command)
  await useFirebaseAuth().refreshToken()

  if (!command.privileges.includes(Privilege.observe)) {
    location.reload()
  }
}

export const ensureTenantActive = async (tenantId: string): Promise<boolean> => {
  if (useAuthState().getTenantId() === tenantId) {
    return true
  }

  const nextTenant = useFirebaseWatch().userOrganizations.value?.find((o) => o.organizationId === tenantId)

  if (nextTenant) {
    /**
     * The user already have access to this tenant -> Make sure it is the active tenant
     */

    const tenantPrivileges = RoleUtils.mapRolesToPrivileges(nextTenant.roles)
    const hasElevatedPrivileges = tenantPrivileges.length > 0
    if (hasElevatedPrivileges) {
      // eslint-disable-next-line no-return-await
      return await gainPrivilegesOverTenant2({ tenantId: tenantId, privileges: tenantPrivileges })
    } else {
      await setActiveTenant(tenantId)
      return useAuthState().getTenantId() === tenantId
    }

  } else {
    /**
     * The user do not have existing access to this tenant -> Try to gain privileges based on user roles
     */

    const userRoles = useAuthState().getUserRoles()
    const tenantPrivileges = RoleUtils.mapRolesToPrivileges(userRoles)
    const hasElevatedPrivileges = tenantPrivileges.length > 0
    if (hasElevatedPrivileges) {
      // eslint-disable-next-line no-return-await
      return await gainPrivilegesOverTenant2({ tenantId: tenantId, privileges: [Privilege.observe] })
    } else {
      return false
    }

  }
}

export const getIconForPrivilege = (privilege: Privilege) => {
  switch (privilege) {
    case Privilege.administrate:
      return 'i-ph:shield-star'
    case Privilege.observe:
      return 'i-ph:eye'

    default:
      return undefined
  }
}

export const hasPrivileges = (roles: TenantRole[]) => {
  return !!getActivePrivilegeFromRole(roles)
}

export const getActivePrivilegeFromRole = (roles: TenantRole[]) => {
  if (roles.includes(TenantRole.tenantAdmin)) {
    return Privilege.administrate
  }
  if (roles.includes(TenantRole.tenantObserver)) {
    return Privilege.observe
  }
  return null
}

export const getAllPrivileges = () => {
  return [Privilege.administrate, Privilege.observe]
}
