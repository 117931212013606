import useContaSession from '@/composables/UseContaSession'
import { useEventService } from '@/plugins/event/useEventService'
import { EventType } from '@/plugins/event/types'
import { initFirestoreWatch, useFirebaseWatch } from '@/plugins/firebase'
import { findOrganization } from '@/services/organization/OrganizationService'
import usePatronService from '@/services/patron/PatronService'
import useAuthState from '@/store/UseAuthState'
import { getFromStorage } from '@/utilities/local-storage'
import i18n from '@/plugins/i18n/i18n'

const { clearEventSubscriptions, addEventSubscription, publishEvent } = useEventService()

export const initializeFinsta = () => {
  useContaSession()

  addEventSubscription(EventType.AUTH_READY, load, false)
}

const load = async () => {
  clearEventSubscriptions(EventType.AUTH_READY)

  setupLocale()

  await setupFirestoreOrgs()

  await setupFinstaOrg()

  initFirestoreWatch()

  publishEvent(EventType.APPLICATION_READY)
}

const setupLocale = () => {
  const locale = getFromStorage('locale')

  if (locale !== null) {
    i18n.global.locale = locale
  }
}

const setupFirestoreOrgs = async () => {
  const uid = useAuthState().uid.value

  if (uid) {
    await useFirebaseWatch().fetchUserOrganizations(uid)
  }
}

const setupFinstaOrg = async () => {
  const orgNo = useAuthState().getOrgNo()

  if (usePatronService().hasProvision() && orgNo) {
    const org = await findOrganization(orgNo)
    useAuthState().setFinstaOrg(org)
  }
}
