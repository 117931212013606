import { Problem } from '@/generated/finsta-client-ts'
import { $t as t } from '@/plugins/i18n/i18n'

export const toProblemMessage = (problem: Problem) => {
  if (problem.code) {
    return '' +
      `${t('problem.code.' + problem.code)}\n\n` +
      `${t('problem.problem-code')}: ${problem.code}`
  }
  if (problem.detail) {
    return '' +
      `${t('problem.problem-details')}: ${problem.detail}` +
      buildInfoString(problem)
  }
  return buildInfoString(problem) || t('problem.unknown-problem')
}

const buildInfoString = (problem: Problem): string => {
  if (!problem) {
    return ''
  }
  const infoMap = problem.info
  if (!infoMap || problem.code) {
    return ''
  }

  let infoString = '\nInfo:\n'

  if (infoMap) {
    Object.keys(infoMap).forEach((k: string) => {
      infoString += `   ${k}: ${infoMap[k]}\n`
    })
  }

  return infoString
}
