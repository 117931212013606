import { AccountingObligationType, Operator } from '@/generated/finsta-client-ts'
import { User } from 'firebase/auth'

export type RegularObject = {
  [key: string]: any
}

export type SpecKey = {
  type: AccountingObligationType
  year: number
}

export enum UserRole {
  support = 'sup',
  admin = 'adm',
  developer = 'dev'
}

export enum Privilege {
  observe = 'observe',
  administrate = 'administrate'
}

export type UpdatePrivilegesCommand = {
  tenantId: string,
  privileges: Privilege[]
}

export enum ReadWriteMode {
  READ = 'read',
  WRITE = 'write'
}

export type Dictionary<T> = { [key: string]: T }

export interface ExtendedUser extends User {
  accessToken?: string,
  claims: RegularObject
}

export type FirestoreUserOrgResult = {
  displayName: string
  email: string
  organizationId: string
  organizationName: string
  organizationNumber: string
  objectID: string
  protected: boolean
  userId: string
}

export interface Part {
  postNo: string
  operator: Operator
}

export enum YesNoOption {
  YES = 'Yes',
  NO = 'No',
  UNANSWERED = 'Unanswered'
}

export enum Provided {
  DETAILED_VIEW = 'detailed-view',
  SHOW_EMPTY = 'show-empty',
  TOGGLE_SHOW_EMPTY = 'toggle-show-empty',
  ID_CLOSEST_TO_TOP = 'id-closest-to-top',
  TOC_CONFIG = 'toc-config'
}

export enum UserSettings {
  GLOBAL_DETAILED_VIEW = 'global-detailed-view',
  GLOBAL_SHOW_EMPTY_POSTS = 'global_show_empty_posts'
}

export interface MenuQuickOption {
  icon: String,
  title: String,
  func: Function
}

export interface MenuItem {
  id: number,
  title: String,
  route: String,
  path: String,
  icon: String,
  disabled?: Boolean,
  partialPath: String,
  subMenuItems?: MenuItem[]
  quickOption?: MenuQuickOption
  hash?: string,
  scrollToId?: string
}

export interface MenuBlock {
  title: String,
  items: MenuItem[]
}

export enum SlideInMode {
  Create = 'create',
  Update = 'update'
}

export enum ApprovalUnavailableReason {
  UNSAVED_CHANGES = 'unsaved-changes',
  ROE_SUM_NOT_ZERO = 'roe-sum-not-zero'
}

export interface EventMap {
  approvalStatus: {
    identifier: string,
    canApprove: boolean,
    reason: ApprovalUnavailableReason | undefined
  }
}

export const defaultInjectionFunction = (p: any = undefined) => {
  console.error('Error: A function injection failed')
}

export enum DefaultStageState {
  NotSubmitted = 'NotSubmitted',
  NotSigned = 'NotSigned'
}
