<template>
  <transition-group id="toast-container"
                    tag="div"
                    name="list"
                    class="font-finsta-default fixed right-2 bottom-0 z-[9000] w-[350px] max-w-[350px] flex flex-col items-center mt-2">

    <toast class="mb-2 z-[9010]"
           v-for="toast in reverseList(toasts)"
           :key="JSON.stringify(toast)"
           :toast="toast"
           :close="() => closeToast(toast.id)"
           @mouseover="() => pauseToastTimer(toast.id)"
           @mouseleave="() => resumeToastTimer(toast.id)"
    />

  </transition-group>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import Toast from '@/plugins/toast/Toast.vue'
import { ToastComponent, ToastMessage, ToastType } from '@/plugins/toast/types'
import Timer from './Timer'

export default defineComponent<ToastComponent>({
  components: { Toast },
  setup () {
    const toasts = ref<ToastMessage[]>([])
    const timers = ref<Map<string, Timer>>(new Map())

    const generateToastId = () => Math.floor(Math.random() * (999999999 - 100000000) + 100000000).toString()

    const showInfo = (message: ToastMessage) => showToast({ ...message, type: ToastType.Information })
    const showWarning = (message: ToastMessage) => showToast({ ...message, type: ToastType.Warning })
    const showError = (message: ToastMessage) => showToast({ ...message, type: ToastType.Error })
    const showSuccess = (message: ToastMessage) => showToast({ ...message, type: ToastType.Success })

    const showToast = (message: ToastMessage) => {
      const toast: ToastMessage = { ...message, id: generateToastId() }
      toasts.value.push(toast)

      if (toast.duration) {
        startToastTimer(toast.id!, toast.duration)
      }
    }

    const closeToast = (toastId: string) => {
      const index = toasts.value.findIndex((toast: ToastMessage) => toast.id === toastId)
      if (index > -1) {
        toasts.value.splice(index, 1)
        stopToastTimer(toastId)
      }
    }

    const startToastTimer = (toastId: string, duration: number) => {
      const timer = new Timer(() => closeToast(toastId), duration)
      timers.value.set(toastId, timer)
    }

    const stopToastTimer = (toastId: string) => {
      const timer = timers.value.get(toastId)
      if (timer) {
        timers.value.delete(toastId)
        timer.stop()
      }
    }

    const pauseToastTimer = (toastId: string) => {
      const timer = timers.value.get(toastId)
      if (timer) {
        timer.pause()
      }
    }

    const resumeToastTimer = (toastId: string) => {
      const timer = timers.value.get(toastId)
      if (timer) {
        timer.resume()
      }
    }

    return {
      toasts,
      reverseList: (list: any[]) => [...list].reverse(),
      showToast,
      pauseToastTimer,
      resumeToastTimer,
      closeToast,
      showInfo,
      showWarning,
      showSuccess,
      showError
    }
  }
})
</script>

<style scoped>

</style>
