// tslint:disable
/**
 * finsta
 * Finsta OpenAPI spec
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Observable } from 'rxjs';
import { BaseAPI, HttpHeaders, HttpQuery, throwIfNullOrUndefined, encodeURI, OperationOpts, RawAjaxResponse } from '../runtime';
import {
    CreateOrganizationCommand,
    DeleteOrganizationCommand,
    Organization,
    UpdateOrganizationCommand,
} from '../models';

export interface CreateOrganizationRequest {
    createOrganizationCommand: CreateOrganizationCommand;
}

export interface DeleteOrganizationRequest {
    deleteOrganizationCommand: DeleteOrganizationCommand;
}

export interface FindOrganizationRequest {
    orgNo: string;
}

export interface FindOrganizationsRequest {
    orgNo?: string | null;
    businessName?: string | null;
    establishedAt?: string | null;
    dissolvedAt?: string | null;
    businessType?: string | null;
    page?: number | null;
    size?: number | null;
}

export interface UpdateOrganizationRequest {
    updateOrganizationCommand: UpdateOrganizationCommand;
}

/**
 * no description
 */
export class OrganizationApi extends BaseAPI {

    /**
     */
    createOrganization({ createOrganizationCommand }: CreateOrganizationRequest): Observable<Organization>
    createOrganization({ createOrganizationCommand }: CreateOrganizationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Organization>>
    createOrganization({ createOrganizationCommand }: CreateOrganizationRequest, opts?: OperationOpts): Observable<Organization | RawAjaxResponse<Organization>> {
        throwIfNullOrUndefined(createOrganizationCommand, 'createOrganizationCommand', 'createOrganization');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Organization>({
            url: '/organizations',
            method: 'POST',
            headers,
            body: createOrganizationCommand,
        }, opts?.responseOpts);
    };

    /**
     */
    deleteOrganization({ deleteOrganizationCommand }: DeleteOrganizationRequest): Observable<void>
    deleteOrganization({ deleteOrganizationCommand }: DeleteOrganizationRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>>
    deleteOrganization({ deleteOrganizationCommand }: DeleteOrganizationRequest, opts?: OperationOpts): Observable<void | RawAjaxResponse<void>> {
        throwIfNullOrUndefined(deleteOrganizationCommand, 'deleteOrganizationCommand', 'deleteOrganization');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<void>({
            url: '/organizations',
            method: 'DELETE',
            headers,
            body: deleteOrganizationCommand,
        }, opts?.responseOpts);
    };

    /**
     */
    findOrganization({ orgNo }: FindOrganizationRequest): Observable<Organization>
    findOrganization({ orgNo }: FindOrganizationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Organization>>
    findOrganization({ orgNo }: FindOrganizationRequest, opts?: OperationOpts): Observable<Organization | RawAjaxResponse<Organization>> {
        throwIfNullOrUndefined(orgNo, 'orgNo', 'findOrganization');

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Organization>({
            url: '/organizations/{orgNo}'.replace('{orgNo}', encodeURI(orgNo)),
            method: 'GET',
            headers,
        }, opts?.responseOpts);
    };

    /**
     */
    findOrganizations({ orgNo, businessName, establishedAt, dissolvedAt, businessType, page, size }: FindOrganizationsRequest): Observable<Array<Organization>>
    findOrganizations({ orgNo, businessName, establishedAt, dissolvedAt, businessType, page, size }: FindOrganizationsRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Array<Organization>>>
    findOrganizations({ orgNo, businessName, establishedAt, dissolvedAt, businessType, page, size }: FindOrganizationsRequest, opts?: OperationOpts): Observable<Array<Organization> | RawAjaxResponse<Array<Organization>>> {

        const headers: HttpHeaders = {
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        const query: HttpQuery = {};

        if (orgNo != null) { query['orgNo'] = orgNo; }
        if (businessName != null) { query['businessName'] = businessName; }
        if (establishedAt != null) { query['establishedAt'] = establishedAt; }
        if (dissolvedAt != null) { query['dissolvedAt'] = dissolvedAt; }
        if (businessType != null) { query['businessType'] = businessType; }
        if (page != null) { query['page'] = page; }
        if (size != null) { query['size'] = size; }

        return this.request<Array<Organization>>({
            url: '/organizations',
            method: 'GET',
            headers,
            query,
        }, opts?.responseOpts);
    };

    /**
     */
    updateOrganization({ updateOrganizationCommand }: UpdateOrganizationRequest): Observable<Organization>
    updateOrganization({ updateOrganizationCommand }: UpdateOrganizationRequest, opts?: OperationOpts): Observable<RawAjaxResponse<Organization>>
    updateOrganization({ updateOrganizationCommand }: UpdateOrganizationRequest, opts?: OperationOpts): Observable<Organization | RawAjaxResponse<Organization>> {
        throwIfNullOrUndefined(updateOrganizationCommand, 'updateOrganizationCommand', 'updateOrganization');

        const headers: HttpHeaders = {
            'Content-Type': 'application/json',
            ...(this.configuration.username != null && this.configuration.password != null ? { Authorization: `Basic ${btoa(this.configuration.username + ':' + this.configuration.password)}` } : undefined),
        };

        return this.request<Organization>({
            url: '/organizations',
            method: 'PUT',
            headers,
            body: updateOrganizationCommand,
        }, opts?.responseOpts);
    };

}
