import URLResolver from '@/services/URLResolver'
import { generateRandomUuid } from '@/utilities/uuid/UuidUtils'
import { defineStore } from 'pinia'
import { ref } from 'vue'

export enum DeploymentEnvironment {
  development = 'development',
  test = 'test',
  staging = 'staging',
  preproduction = 'preproduction',
  production = 'production'
}

export type RemoteConfigurationOptions = {
  environment: DeploymentEnvironment
}

export const applicationSettingsStore = defineStore('application-settings', () => {

  const sessionUuid = generateRandomUuid()

  const version = __APP_VERSION__ || '0'

  const deploymentEnvironment = ref(DeploymentEnvironment.development)

  const useServiceWorker = ref(false)

  const loadEnvironmentConfig = async () => {
    const envConfigUrl = `${URLResolver.resolveApiUrl()}/config/info`

    const envConfigResponse = await fetch(envConfigUrl).catch(() => {
      return undefined
    })

    const envConfigData: RemoteConfigurationOptions | undefined = await envConfigResponse?.json()
    if (envConfigData) {
      deploymentEnvironment.value = envConfigData.environment
      useServiceWorker.value = deploymentEnvironment.value !== DeploymentEnvironment.development
    }
  }

  return {
    sessionUuid,
    version,
    deploymentEnvironment,
    useServiceWorker,

    loadEnvironmentConfig
  }
})
