export type StopHandle = () => void

export enum ToastType { Information, Error, Warning, Success }

export enum ToastCategory {
  FinancialStatement = 'toast-category.financial-statement',
  Import = 'toast-category.trial-balance-import',
  TrialBalance = 'toast-category.trial-balance',
  DataInput = 'toast-category.data-input',
  IncomeStatement = 'toast-category.income-statement'
}

export type ToastMessage = {
  type?: ToastType,
  id?: string,
  externalServiceName?: string,
  title?: string,
  message: string,
  duration?: number,
  dismissable?: boolean
}

export type ToastComponent = {
  showInfo: (toast: ToastMessage) => StopHandle,
  showError: (toast: ToastMessage) => StopHandle,
  showWarning: (toast: ToastMessage) => StopHandle,
  showSuccess: (toast: ToastMessage) => StopHandle
}
