import FirebaseConfig from '@/plugins/firebase/config/FirebaseConfig'
import { FirebaseOptions } from 'firebase/app'
import { defineStore } from 'pinia'
import { ref } from 'vue'

const DEMO_PROJECT_ID = 'demo-tritt'

const DEFAULT_EMULATOR_HOST = 'arsoppgjor.tritt.test'

const DEFAULT_FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: 'fake-api-key',
  authDomain: 'fake-auth-domain',
  projectId: 'demo-tritt',
  appId: 'fake-app-id'
}

export const firebaseConfigStore = defineStore('firestore-config', () => {

  const firebaseConfig = ref(DEFAULT_FIREBASE_CONFIG)

  const useFirebaseEmulator = ref(false)

  const firebaseEmulatorHost = ref(DEFAULT_EMULATOR_HOST)

  const loadFirebaseConfig = async () => {
    const response: FirebaseOptions = await FirebaseConfig()

    const config = {
      ...response,
      authDomain: window.location.hostname
    }

    firebaseConfig.value = config
    useFirebaseEmulator.value = config.projectId === DEMO_PROJECT_ID
  }

  return {
    firebaseConfig,
    loadFirebaseConfig,

    useFirebaseEmulator,
    firebaseEmulatorHost,
  }

})
