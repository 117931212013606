import { applicationSettingsStore, DeploymentEnvironment } from '@/store/applicationSettings.store'
import { ComponentPublicInstance } from 'vue'
import { ErrorLogFunction } from '@/composables/types'

let loggers:ErrorLogFunction[] = []

const registerLogger = (logFunc: ErrorLogFunction) => {
  loggers.push(logFunc)
}

export const initializeLoggers = () => {
  if (applicationSettingsStore().deploymentEnvironment !== DeploymentEnvironment.development) {
    import('@/utilities/loggers/TrackJSLogger').then((logger) => {
      registerLogger(logger.default)
    })
  }

  if (applicationSettingsStore().deploymentEnvironment === DeploymentEnvironment.development) {
    import('@/utilities/loggers/ConsoleLogger').then((logger) => {
      registerLogger(logger.default)
    })
  }

  if (import.meta.hot) {
    import.meta.hot.data.loggers = loggers
  }
}

if (import.meta.hot) {
  /* HMR enabled */

  if (import.meta.hot.data.loggers) {
    loggers = import.meta.hot.data.loggers
  }
}

const useErrorLogging = () => {
  const logError = (error: Error, instance?: ComponentPublicInstance | undefined | null, info?: any) => {
    loggers.forEach((l) => l(error, instance, info))
  }
  return {
    logError
  }
}

export default useErrorLogging
