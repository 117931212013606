import { UserRole } from '@/types'

export enum FirestoreCollections {
  users = 'users',
  organizations = 'userOrganizations',
  sessions = 'sessions'
}

export enum TenantRole {
  owner = 'own',
  tenantAdmin = 'ta',
  tenantObserver = 'to',
  salaryEmployee = 'se',
  salaryManager = 'sm',
  finstaManager = 'fm',
  support = 'sup',
  admin = 'adm',
  developer = 'dev',
  manager = 'manager',
  employee = 'employee',
  unknown = 'unknown'
}

export enum FirestoreUserField {
  email = 'email',
  displayName = 'displayName',
  phoneNumber = 'phoneNumber',
  language = 'language',
  settings = 'settings',
  roles = 'roles'
}

export enum FirestoreOrgField {
  organizationId = 'organizationId',
  organizationName = 'organizationName',
  organizationNumber = 'organizationNumber',
  protected = 'protected',
  roles = 'roles'
}

export type FirestoreOrg = {
  id: string
  roles: TenantRole[]
  admin?: boolean
  protected?: boolean
  organizationId: string
  organizationName: string
  organizationNumber: string
}

export enum ApplicationLocale {
  norwegianBokmal = 'nb',
  englishUS = 'en'
}

export type FirestoreUser = {
  id: string
  displayName?: string
  phoneNumber?: string
  email?: string
  language?: ApplicationLocale
  settings?: Record<string, any>
  roles?:UserRole[]
}

export enum FirestoreSessionField {
  id = 'id',
  ipAddress = 'ipAddress',
  startedAt = 'startedAt',
  endedAt = 'endedAt',
  userAgent = 'userAgent',
  userId = 'userId'
}

export type FirestoreTimestamp = {
  nanoseconds: number
  seconds: number
}

export type FirestoreSession = {
  id: string
  ipAddress: string
  startedAt?: number
  endedAt?: number
  userAgent: string
  userId: string
}
