import { RouteParams } from 'vue-router'
import { homeRoute, RouteObject, FinancialStatementParams } from '@/router/paths'
import { getRouter } from '@/router/router'
import { RegularObject } from '@/types'

export const useRouter = () => {

  const getCurrentPath = () => getRouter().currentRoute.value.path + getRouter().currentRoute.value.hash

  const getParams = (): RouteParams => getRouter().currentRoute.value.params

  const navigateToPath = async (path: string) => getRouter().push(path)

  const navigateToRoute = async (route: RouteObject<any>, overrides: RegularObject = {}) => {
    return getRouter().push(
      route.route({
        ...getParams(),
        ...overrides
      })
    )
  }

  return {
    getCurrentPath,

    getParams,

    navigateToPath,

    navigateToRoute,

    navigateToRouteAndScroll: async (
      route: RouteObject<FinancialStatementParams>,
      overrides: RegularObject = {},
      scrollTo: RegularObject = { top: 0 }
    ) => {
      await navigateToRoute(route, overrides)
      window.scrollTo(scrollTo)
    },

    goBack: async () => getRouter().back(),

    replaceUrl: async (url: string) => getRouter().replace(url),

    getParamValue: (key: string): string => {
      const value = getParams()[key]

      if (typeof value === 'string') {
        return value
      } else if (typeof value === 'object') {
        return value[0]
      }

      return ''
    },

    isCurrentPath: (path: string): boolean => getCurrentPath() === path,

    isCurrentPathPart: (path: string): boolean => {
      const currentRoute = getCurrentPath()

      if (currentRoute === homeRoute.route() || path === homeRoute.route()) {
        return currentRoute === path
      } else {
        return currentRoute.includes(path)
      }
    }
  }
}
