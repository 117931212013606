<template>
  <div class="toast shadow-light relative max-h-[400px] max-w-[320px] md:max-w-[350px] overflow-y-auto w-full rounded bg-white text-black border-4 "
       :class="[borderColor, textColor]"
       role="alert">
    <div class="w-full flex justify-between">
      <div class="p-2 font-bold">
        <font-awesome-icon :icon="icon" :class="[iconColor]" />
        {{ toast.title }}
      </div>
      <button @click="close" class="p-1">
        <font-awesome-icon icon="times-circle" class="text-2xl text-finsta-dark" :class="[textColor]" />
      </button>
    </div>
    <p v-if="toast.externalServiceName" class="w-full px-3 pt-2 italic font-medium">
      {{ t('received-response-from') }} {{ toast.externalServiceName }}:
    </p>
    <div class="toast-message">
      {{ toast.message }}
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
import { ToastMessage, ToastType } from '@/plugins/toast/types'
import { $t as t } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'Toast',
  props: {
    toast: { type: Object as () => ToastMessage, required: true },
    close: { type: Function, default: null }
  },
  setup (props) {
    const borderColor = computed(() => {
      switch (props.toast.type) {
        case ToastType.Information:
          return 'border-finsta-info'
        case ToastType.Warning:
          return 'border-finsta-warning'
        case ToastType.Error:
          return 'border-brand-error'
        case ToastType.Success:
          return 'border-finsta-success'
        default:
          return 'border-finsta-interact'
      }
    })

    const textColor = computed(() => {
      switch (props.toast.type) {
        case ToastType.Information:
        case ToastType.Warning:
        case ToastType.Error:
          return 'text-finsta-dark'
        case ToastType.Success:
          return 'text-white'
        default:
          return 'text-white'
      }
    })

    const icon = computed(() => {
      switch (props.toast.type) {
        case ToastType.Information:
          return 'info-circle'
        case ToastType.Warning:
          return 'exclamation-circle'
        case ToastType.Error:
          return 'exclamation-triangle'
        case ToastType.Success:
          return 'check'
        default:
          return 'info-circle'
      }
    })

    const iconColor = computed(() => {
      switch (props.toast.type) {
        case ToastType.Information:
        case ToastType.Warning:
          return 'text-finsta-warning'
        case ToastType.Error:
          return 'text-brand-error'
        case ToastType.Success:
          return 'text-finsta-success'
        default:
          return 'black'
      }
    })

    return {
      t,
      borderColor,
      textColor,
      icon,
      iconColor
    }
  }
})
</script>

<style scoped lang="postcss">

.toast {
  /*box-shadow: 0 0 10px 0 rgba(66, 60, 60, 1);*/
  /*-webkit-box-shadow: 0 0 10px 0 rgba(66, 60, 60, 1);*/
  /*-moz-box-shadow: 0 0 10px 0 rgba(66, 60, 60, 1);*/

  opacity: 1;
  backdrop-filter: blur(2px);
  transition: opacity 0.2s ease;
}

.toast:hover {
  opacity: 1.0;
}

.toast-message {
  @apply w-full px-3 pb-3 pt-2;
  white-space: pre-wrap;
  word-break: break-word;
}

</style>
