import { ApiError, handleApiError } from '@/utilities/error/ErrorHandler'
import { useFirebaseAuth } from '@/plugins/firebase'

export type PromiseData<T, U> = {data?:T, error?:U}

export default class PromiseUtils {
  private static async handlePromiseInternal<T, U> (promise:Promise<T>):Promise<PromiseData<T, U>> {
    return promise
      .then((data) => { return { data, error: undefined } })
      .catch((error) => Promise.resolve({ data: undefined, error }))
  }

  static async handlePromise<T, U> (promise:Promise<T>):Promise<PromiseData<T, U>> {
    await useFirebaseAuth().refreshStoreToken()
    return this.handlePromiseInternal(promise)
  }

  static async simpleRequestHandler<T> (promise: Promise<T>): Promise<T | undefined> {
    await useFirebaseAuth().refreshStoreToken()
    const { data, error } = await PromiseUtils.handlePromiseInternal(promise)
    if (error) handleApiError(error as ApiError)
    return data
  }
}
