import RequestInterceptor from '@/services/configuration/Middleware'
import { resolveFinstaApiPath } from '@/services/ServerBasePathResolver'
import { Configuration, OrganizationApi } from '@/generated/finsta-client-ts'

const client = new OrganizationApi(new Configuration({
  basePath: resolveFinstaApiPath(),
  middleware: RequestInterceptor.Instance.middleware
}))

export default client
