import {
  AnnualStatement, AnnualStatementSpec, Note, BalanceAccount, BusinessInformation, BusinessInformationSpec, FinancialStatement, Range, TrialBalance, TrialBalanceSpec, YearEndClosing, TaxReturnSpec,
  TaxReturn, AccountingTransaction, FinancialStatementKeyInformation, Background, BackgroundSpec, PostTrait, TaxReturnDocument
} from '@/generated/finsta-client-ts'
import { createStore } from 'vuex'

export type RootState = {
  financialStatement: FinancialStatement | undefined,
  traitOverrides: PostTrait[]
  background: Background | undefined,
  backgroundSpec: BackgroundSpec | undefined,
  trialBalanceSpec: TrialBalanceSpec | undefined,
  trialBalance: TrialBalance | undefined,
  transactions: AccountingTransaction[]
  annualStatementSpec: AnnualStatementSpec | undefined,
  annualStatement: AnnualStatement | undefined,
  businessInformationSpec: BusinessInformationSpec | undefined,
  businessInformation: BusinessInformation | undefined,
  yearEndClosing: YearEndClosing | undefined,
  taxReturn: TaxReturn | undefined,
  taxReturnSpec: TaxReturnSpec | undefined
}

export default createStore<RootState>({
  state: {
    financialStatement: undefined,
    traitOverrides: [],
    background: undefined,
    backgroundSpec: undefined,
    trialBalance: undefined,
    trialBalanceSpec: undefined,
    transactions: [],
    annualStatementSpec: undefined,
    annualStatement: undefined,
    businessInformationSpec: undefined,
    businessInformation: undefined,
    yearEndClosing: undefined,
    taxReturn: undefined,
    taxReturnSpec: undefined
  },
  getters: {
    getUnsupportedTraits: (state: RootState) => (): PostTrait[] => {
      return state.backgroundSpec?.unsupportedTraits || []
    },
    getExperimentalTraits: (state: RootState) => (): PostTrait[] => {
      return state.backgroundSpec?.experimentalTraits || []
    },
    getAllTraits: (state: RootState) => (): PostTrait[] => {
      return (state.background?.traits || []).concat(state.traitOverrides)
    },
    isActiveTrait: (state: RootState, getters) => (trait: PostTrait): boolean => {
      return !!getters.getAllTraits().find((t: PostTrait) => t === trait)
    },
    getAnswerActivatedTraits: (state: RootState) => (): PostTrait[] => {
      return state.background?.traits || []
    },
    getTrialBalanceChecksum: (state: RootState) => (): string | null => {
      const cs = state.trialBalance?.checksum
      return cs || null
    },
    getBackgroundChecksum: (state: RootState) => (): string | null => {
      const cs = state.background?.checksum
      return cs || null
    },
    getBusinessInformationChecksum: (state: RootState) => (): string | null => {
      const cs = state.businessInformation?.checksum
      return cs || null
    },
    getYearEndClosingChecksum: (state: RootState) => (): string | null => {
      const cs = state.yearEndClosing?.checksum
      return cs || null
    },
    getTaxReturnChecksum: (state: RootState) => (): string | null => {
      const cs = state.taxReturn?.checksum
      return cs || null
    },
    getAnnualStatementChecksum: (state: RootState) => (): string | null => {
      const cs = state.annualStatement?.checksum
      return cs || null
    },
    getAccountsInRange: (state: RootState) => (ranges: Range[]) => {
      const trialBalance = state.trialBalance
      if (!trialBalance) {
        return []
      }

      const accounts = trialBalance.accounts.filter((e: BalanceAccount) =>
        ranges.find((r: Range) => {
          const accountNo = parseInt(e.accountNo, 10)
          const from = parseInt(r.from, 10)
          const to = parseInt(r.to, 10)
          return from <= accountNo && accountNo <= to
        })
      )

      return accounts.sort((a, b) => parseInt(a.accountNo, 10) - parseInt(b.accountNo, 10))
    },
    getFinancialStatementKeyInfo: (state: RootState) => (): FinancialStatementKeyInformation | undefined => state.financialStatement?.keyInfo,

    getNoteByOrdinal: (state: RootState) => (ordinal: number): Note | undefined => {
      const report = state.annualStatement?.notes

      if (!report) {
        return undefined
      }

      return [...Object.values(report), ...(report?.textNotes || [])]
        .filter((f): f is Note => f && typeof f === 'object' && 'header' in f)
        .find(n => n.header.ordinal === ordinal)
    }
  },
  mutations: {
    setFinancialStatement: (state: RootState, financialStatement) => state.financialStatement = financialStatement,
    setTrialBalanceSpec: (state: RootState, trialBalanceSpec) => state.trialBalanceSpec = trialBalanceSpec,
    setTrialBalance: (state: RootState, trialBalance: TrialBalance) => {
      state.trialBalance = trialBalance
      state.transactions = trialBalance.transactions || []
    },
    setBusinessInformationSpec: (state: RootState, businessInformationSpec) => state.businessInformationSpec = businessInformationSpec,
    setBusinessInformation: (state: RootState, businessInformation: BusinessInformation) => state.businessInformation = businessInformation,
    setBackground: (state: RootState, background: Background) => state.background = background,
    setBackgroundDraft: (state: RootState, draft: TaxReturnDocument) => {
      if (state.background) {
        state.background.taxReturnDraft = draft
      } else {
        state.background = { taxReturnDraft: draft }
      }
    },
    toggleTraitOverride: (state: RootState, trait: PostTrait) => {
      if (state.traitOverrides.includes(trait)) {
        state.traitOverrides = state.traitOverrides.filter((t: PostTrait) => t !== trait)
      } else {
        state.traitOverrides.push(trait)
      }
    },
    setBackgroundSpec: (state: RootState, backgroundSpec) => state.backgroundSpec = backgroundSpec,
    setTaxReturnSpec: (state: RootState, taxReturnSpec) => state.taxReturnSpec = taxReturnSpec,
    setTaxReturn: (state: RootState, taxReturn: TaxReturn) => state.taxReturn = taxReturn,
    setYearEndClosing: (state: RootState, yec: YearEndClosing) => state.yearEndClosing = yec,
    setAnnualStatementSpec: (state: RootState, annualStatementSpec) => state.annualStatementSpec = annualStatementSpec,
    setAnnualStatement: (state: RootState, annualStatement) => state.annualStatement = annualStatement,
    setAnnualStatementDetails: (state: RootState, details) => {
      if (!state.annualStatement) {
        return
      }
      state.annualStatement!.details = details
    },
    setDefaultFinancialStatementState: (state: RootState) => {
      state.financialStatement = undefined
      state.background = undefined
      state.traitOverrides = []
      state.backgroundSpec = undefined
      state.trialBalance = undefined
      state.transactions = []
      state.businessInformationSpec = undefined
      state.businessInformation = undefined
      state.annualStatementSpec = undefined
      state.annualStatement = undefined
      state.yearEndClosing = undefined
      state.taxReturnSpec = undefined
      state.taxReturnSpec = undefined
    }
  },
  actions: {
    setDefaultState: ({ commit }) => {
      commit('setDefaultFinancialStatementState')
    }
  },
  modules: {}
})
