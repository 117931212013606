export enum RouteParam {
  TENANT_ID = 'tenantId',
  UUID = 'uuid'
}

export interface TenantParams {
  tenantId: string
}

export interface FinancialStatementParams {
  tenantId: string,
  uuid: string
}

interface RouteObjectBase {
  fullPath: string,
  path: string,
  component: any,
  name: string,
  text: string,
  hash?: string,
  scrollToId?: string
}

export interface RouteObjectSimple extends RouteObjectBase {
  route: () => string,
}

export interface RouteObject<T> extends RouteObjectBase {
  route: (args: T) => string,
}

export interface FinstaRoute {
  name: string,
  text: string,
  path: string
  scrollToId?: string
}

export const mapToFinstaRoute = (routeObject: RouteObjectSimple | RouteObject<any>, params: any = undefined): FinstaRoute => {
  return {
    name: routeObject.name,
    text: routeObject.text,
    path: routeObject.route(params),
    scrollToId: routeObject.scrollToId
  } as FinstaRoute
}

/** Organization routes */
export const organizationsRoute: RouteObjectSimple = {
  name: 'organizations',
  text: 'organization.registered-businesses',
  fullPath: '/organizations',
  path: '/organizations',
  route: () => '/organizations',
  component: () => import('@/views/organizations/OrganizationsView.vue')
}

export const registeredOrganizationRoute: RouteObjectSimple = {
  name: 'registered-organization-callback',
  text: 'organization.list',
  fullPath: '/registered-organization-callback',
  path: '/registered-organization-callback',
  route: () => '/setup-registered-organization',
  component: () => import('@/views/patron/RegisteredOrganizationCallbackView.vue')
}

/** Patron routes */

export const subscriptionStatusRoute: RouteObject<TenantParams> = {
  name: 'subscription-status',
  text: 'patron.subscription-status.subscription-status',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/subscription-status`,
  path: `/organization/:${RouteParam.TENANT_ID}/subscription-status`,
  route: (params: TenantParams) => `/organization/${params.tenantId}/subscription-status`,
  component: () => import('@/views/patron/SubscriptionStatusView.vue')
}

export const provisionStatusRoute: RouteObject<TenantParams> = {
  name: 'provision-status',
  text: 'patron.provision-status.service-status',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/provision-status`,
  path: `/organization/:${RouteParam.TENANT_ID}/provision-status`,
  route: (params: TenantParams) => `/organization/${params.tenantId}/provision-status`,
  component: () => import('@/views/patron/ProvisionStatusView.vue')
}

export const onboardingStatusRoute: RouteObject<TenantParams> = {
  name: 'onboarding-status',
  text: 'patron.onboarding.get-started',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/onboarding-status`,
  path: `/organization/:${RouteParam.TENANT_ID}/onboarding-status`,
  route: (params: TenantParams) => `/organization/${params.tenantId}/onboarding-status`,
  component: () => import('@/views/patron/OnboardingStatusView.vue')
}

/** Home routes */

const HomeView = () => import('@/views/home/HomeView.vue')
export const homeRoute: RouteObjectSimple = {
  name: 'Home',
  text: '',
  fullPath: '/',
  path: '/',
  route: () => '/',
  component: HomeView
}

export const allFinancialStatementsRoute: RouteObject<TenantParams> = {
  name: 'all-financial-statements',
  text: 'financial-statements.your-financial-statements',
  fullPath: `/organization/organization/:${RouteParam.TENANT_ID}/financial-statements/all`,
  path: `/organization/:${RouteParam.TENANT_ID}/financial-statements/all`,
  route: (params: TenantParams) => `/organization/${params.tenantId}/financial-statements/all`,
  component: () => import('@/views/home/all-financial-statements/AllFinancialStatementsView.vue')
}

const NewFinancialStatement = () => import('@/views/home/new-financial-statement/NewFinancialStatementView.vue')
export const newFinancialStatementRoute: RouteObject<TenantParams> = {
  name: 'new-financial-statements',
  text: 'financial-statements.new-financial-statement',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/financial-statements/new`,
  path: `/organization/:${RouteParam.TENANT_ID}/financial-statements/new`,
  route: (params: TenantParams) => `/organization/${params.tenantId}/financial-statements/new`,
  component: NewFinancialStatement
}

/** Financial statement routes */

const financialStatementBaseRoute = 'financial-statements'

export const financialStatementRoute: RouteObject<FinancialStatementParams> = {
  name: 'financial-statement',
  text: '',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}`,
  path: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}`,
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}`,
  component: () => import('@/views/financial-statement/FinancialStatementView.vue')
}

export const supportRoute: RouteObject<FinancialStatementParams> = {
  name: 'support',
  text: 'page-header.support',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/support`,
  path: 'support',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/support`,
  component: () => import('@/views/support/SupportView.vue')
}

export const statusRoute: RouteObject<FinancialStatementParams> = {
  name: 'status',
  text: 'page-header.status',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/status`,
  path: 'status',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/status`,
  component: () => import('@/views/status/StatusView.vue')
}

export const backgroundRoute: RouteObject<FinancialStatementParams> = {
  name: 'background',
  text: 'page-header.background',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/background`,
  path: 'background',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/background`,
  component: () => import('@/views/background/BackgroundBaseView.vue')
}

export const draftRoute: RouteObject<FinancialStatementParams> = {
  name: 'draft',
  text: 'page-header.draft',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/background/draft`,
  path: 'draft',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/background/draft`,
  component: () => import('@/views/background/draft/DraftView.vue')
}

export const questionsRoute: RouteObject<FinancialStatementParams> = {
  name: 'questions',
  text: 'page-header.questions',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/background/questions`,
  path: 'questions',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/background/questions`,
  component: () => import('@/views/background/questions/QuestionsView.vue')
}

export const accountingRoute: RouteObject<FinancialStatementParams> = {
  name: 'accounting',
  text: 'page-header.accounting',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/accounting`,
  path: 'accounting',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/accounting`,
  component: () => import('@/views/accounting/AccountingView.vue')
}

export const trialBalanceRoute: RouteObject<FinancialStatementParams> = {
  name: 'trial-balance',
  text: 'page-header.trial-balance',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/accounting/trial-balance`,
  path: 'trial-balance',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/accounting/trial-balance`,
  component: () => import('@/views/accounting/trial-balance/TrialBalanceView.vue')
}

export const transactionRoute: RouteObject<FinancialStatementParams> = {
  name: 'transactions',
  text: 'page-header.transactions',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/accounting/transactions`,
  path: 'transactions',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/accounting/transactions`,
  component: () => import('@/views/accounting/transactions/TransactionView.vue')
}

export const annualStatementRoute: RouteObject<FinancialStatementParams> = {
  name: 'annual-statement',
  text: 'page-header.annual-statement',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/annual-statement`,
  path: 'annual-statement',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/annual-statement`,
  component: () => import('@/views/annual-statement/AnnualStatementView.vue')
}

export const annualStatementResultRoute: RouteObject<FinancialStatementParams> = {
  name: 'annual-statement-result',
  text: 'page-header.annual-statement/result',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/annual-statement/result`,
  path: 'result',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/annual-statement/result`,
  component: () => import('@/views/annual-statement/result-and-balance/ResultView.vue')
}

export const annualStatementBalanceRoute: RouteObject<FinancialStatementParams> = {
  name: 'annual-statement-balance',
  text: 'page-header.annual-statement/balance',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/annual-statement/balance`,
  path: 'balance',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/annual-statement/balance`,
  component: () => import('@/views/annual-statement/result-and-balance/BalanceView.vue')
}

export const annualStatementBoardRoute: RouteObject<FinancialStatementParams> = {
  name: 'annual-statement-board',
  text: 'page-header.annual-statement/board',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/annual-statement/board`,
  path: 'board',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/annual-statement/board`,
  component: () => import('@/views/annual-statement/board/BoardView.vue')
}

export const annualStatementNotesRoute: RouteObject<FinancialStatementParams> = {
  name: 'annual-statement-notes',
  text: 'page-header.annual-statement/notes',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/annual-statement/notes`,
  path: 'notes',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/annual-statement/notes`,
  component: () => import('@/views/annual-statement/notes/NotesView.vue')
}

export const annualStatementSubmissionRoute: RouteObject<FinancialStatementParams> = {
  name: 'annual-statement-submission',
  text: 'page-header.annual-statement-submission',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/annual-statement/submission`,
  path: 'submission',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/annual-statement/submission`,
  component: () => import('@/views/annual-statement/submission/AnnualStatementSubmissionView.vue')
}

export const taxReturnBaseRoute: RouteObject<FinancialStatementParams> = {
  name: 'tax-return-base',
  text: 'page-header.tax-return',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/tax-return/`,
  path: 'tax-return',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/tax-return`,
  component: () => import('@/views/tax-return/TaxReturnBaseView.vue')
}

export const taxReturnRoute: RouteObject<FinancialStatementParams> = {
  name: 'tax-return',
  text: 'page-header.tax-return',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/tax-return/tax-return`,
  path: 'tax-return',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/tax-return/tax-return`,
  component: () => import('@/views/tax-return/tax-return/TaxReturnView.vue')
}

export const businessInformationRoute: RouteObject<FinancialStatementParams> = {
  name: 'business-information',
  text: 'page-header.business-information',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/tax-return/business-information`,
  path: 'business-information',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/tax-return/business-information`,
  component: () => import('@/views/tax-return/business-information/BusinessInformationView.vue')
}

export const yearEndClosingRoute: RouteObject<FinancialStatementParams> = {
  name: 'year-end-closing',
  text: 'page-header.year-end-closing',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/tax-return/business-information#year-end-closing`,
  path: 'business-information',
  hash: '#year-end-closing',
  scrollToId: 'YearEndClosing',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/tax-return/business-information#year-end-closing`,
  component: () => import('@/views/tax-return/business-information/BusinessInformationView.vue')
}

export const taxReturnSubmissionRoute: RouteObject<FinancialStatementParams> = {
  name: 'tax-return-submission',
  text: 'page-header.tax-return-submission',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/tax-return/submission`,
  path: 'submission',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/tax-return/submission`,
  component: () => import('@/views/tax-return/submission/TaxReturnSubmissionView.vue')
}

/** Financial instruments routes */

export const finregRoute: RouteObject<FinancialStatementParams> = {
  name: 'finreg',
  text: 'page-header.finreg',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/finreg`,
  path: 'finreg',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/finreg`,
  component: () => import('@/views/finreg/FinregViewWrapper.vue')
}

export const finregStocksRoute: RouteObject<FinancialStatementParams> = {
  name: 'finreg-stocks',
  text: 'page-header.stocks',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/finreg/stocks`,
  path: 'stocks',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/finreg/stocks`,
  component: () => import('@/views/finreg/stocks/StocksView.vue'),
}

/** Asset registry routes */

export const financialStatementAssetRegistryRoute: RouteObject<FinancialStatementParams> = {
  name: 'finsta-asset-registry',
  text: 'page-header.asset-registry',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/asset-registry`,
  path: 'asset-registry',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/asset-registry`,
  component: () => import('@/views/asset-registry/AssetRegistryViewWrapper.vue'),
}

export const finstaAllAssetsRoute: RouteObject<FinancialStatementParams> = {
  name: 'finsta-all-assets',
  text: 'asset-registry.all-assets',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/asset-registry/all-assets`,
  path: 'all-assets',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/asset-registry/all-assets`,
  component: () => import('@/views/asset-registry/assets/AssetsView.vue'),
}

export const finstaAllProfitAndLossAccountsRoute: RouteObject<FinancialStatementParams> = {
  name: 'finsta-all-profit-and-loss-accounts',
  text: 'asset-registry.all-profit-and-loss-accounts',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/asset-registry/all-profit-and-loss-accounts`,
  path: 'all-profit-and-loss-accounts',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/asset-registry/all-profit-and-loss-accounts`,
  component: () => import('@/views/asset-registry/profit-and-loss-accounts/ProfitAndLossAccountsView.vue'),
}

export const finstaAllBalanceGroupsRoute: RouteObject<FinancialStatementParams> = {
  name: 'finsta-all-balance-groups',
  text: 'asset-registry.all-balance-groups',
  fullPath: `/organization/:${RouteParam.TENANT_ID}/${financialStatementBaseRoute}/:${RouteParam.UUID}/asset-registry/all-balance-groups`,
  path: 'all-balance-groups',
  route: (params: FinancialStatementParams) => `/organization/${params.tenantId}/${financialStatementBaseRoute}/${params.uuid}/asset-registry/all-balance-groups`,
  component: () => import('@/views/asset-registry/balance-groups/BalanceGroupsView.vue'),
}

/** Profile routes */

export const profileBaseRoute = '/profile'

const ProfileView = () => import('@/views/profile/ProfileView.vue')
export const profileRoute: RouteObjectSimple = {
  name: 'my-profile',
  text: 'profile.my-profile',
  fullPath: `/${profileBaseRoute}`,
  path: `${profileBaseRoute}`,
  route: () => `${profileBaseRoute}`,
  component: ProfileView
}

const MyProfile = () => import('@/views/profile/my-profile/MyProfileView.vue')
export const myProfileRoute: RouteObjectSimple = {
  name: 'my-profile',
  text: 'profile.my-profile',
  fullPath: '',
  path: 'my-profile',
  route: () => `${profileBaseRoute}/my-profile`,
  component: MyProfile
}

const ProfileSettings = () => import('@/views/profile/profile-settings/ProfileSettingsView.vue')
export const profileSettingsRoute: RouteObjectSimple = {
  name: 'profile-settings',
  text: 'profile.profile-settings',
  fullPath: `/${profileBaseRoute}/profile-settings`,
  path: 'profile-settings',
  route: () => `${profileBaseRoute}/profile-settings`,
  component: ProfileSettings
}

/** Admin routes */

const AdminView = () => import('@/views/admin/AdminView.vue')
export const adminRoute: RouteObjectSimple = {
  name: 'admin',
  text: '',
  fullPath: '/admin/test',
  path: '/admin/test',
  route: () => '/admin/test',
  component: AdminView
}

/** Signout routes */

const SignoutView = () => import('@/views/signout/SignoutView.vue')
export const signoutRoute: RouteObjectSimple = {
  name: 'Signout',
  text: '',
  fullPath: '/signout',
  path: '/signout',
  route: () => '/signout',
  component: SignoutView
}
