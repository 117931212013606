import { useToast } from '@/plugins/toast'
import { $t } from '@/plugins/i18n/i18n'
// eslint-disable-next-line import/no-extraneous-dependencies
import { FirebaseError } from '@firebase/util'

export const handleError = (error?: FirebaseError) => {
  if (error) {
    showError(error.code)
  }
}

export const handleLoginError = (error?: FirebaseError) => {
  if (error) {
    showError(error.code, $t('toast-category.login'))
  }
}

export const handleRegistrationError = (error?: FirebaseError) => {
  if (error) {
    showError(error.code, $t('toast-category.register'))
  }
}

const showError = (code: string, toastCategory: string = '') => {
  if (['auth/popup-closed-by-user', 'auth/cancelled-popup-request'].includes(code)) {
    console.log('FirebaseAuthErrorHandler received error with code: ' + code)
    return
  }
  useToast().showError({
    title: toastCategory,
    message: $t(`firebase-error.${code}`),
    duration: 5000
  })
}
