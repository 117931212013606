import useAuthState from '@/store/UseAuthState'
import { RouteLocation, RouteLocationRaw, RouteRecordRaw } from 'vue-router'
import NotFound from '@/views/NotFound.vue'

import {
  // ORGANIZATION
  organizationsRoute, registeredOrganizationRoute,
  // PATRON
  subscriptionStatusRoute, provisionStatusRoute,
  // ONBOARDING
  onboardingStatusRoute,
  // HOME
  homeRoute, allFinancialStatementsRoute, newFinancialStatementRoute,
  // PROFILE
  profileRoute, myProfileRoute, profileSettingsRoute,
  // ADMIN
  adminRoute,
  // FINANCIAL STATEMENT
  financialStatementRoute,
  // SUPPORT
  supportRoute,
  // STATUS
  statusRoute,
  // BACKGROUND
  backgroundRoute, draftRoute, questionsRoute,
  // TAX RETURN
  taxReturnBaseRoute, businessInformationRoute, taxReturnRoute, taxReturnSubmissionRoute,
  // ACCOUNTING
  accountingRoute, trialBalanceRoute, transactionRoute,
  // ANNUAL STATEMENT
  annualStatementRoute, annualStatementResultRoute, annualStatementBalanceRoute, annualStatementBoardRoute, annualStatementNotesRoute, annualStatementSubmissionRoute,
  // ASSET REGISTRY
  financialStatementAssetRegistryRoute, finstaAllAssetsRoute, finstaAllProfitAndLossAccountsRoute, finstaAllBalanceGroupsRoute,
  // FINANCIAL INSTRUMENT REGISTRY
  finregRoute, finregStocksRoute,
  // SIGNOUT
  signoutRoute
} from '@/router/paths'

const routes: Array<RouteRecordRaw> = [
  {
    ...organizationsRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: false,
      requiresActiveSubscription: false,
      requiresSuccessfulProvision: false,
      requiresOnboarding: false,
      permission: undefined,
    },
  },
  {
    ...registeredOrganizationRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: false,
      requiresActiveSubscription: false,
      requiresSuccessfulProvision: false,
      requiresOnboarding: false,
      permission: undefined,
    },
  },
  {
    ...subscriptionStatusRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: true,
      requiresActiveSubscription: false,
      requiresSuccessfulProvision: false,
      requiresOnboarding: false,
      permission: undefined
    },
  },
  {
    ...provisionStatusRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: true,
      requiresActiveSubscription: true,
      requiresSuccessfulProvision: false,
      requiresOnboarding: false,
      permission: undefined
    },
  },
  {
    ...onboardingStatusRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: true,
      requiresActiveSubscription: true,
      requiresSuccessfulProvision: true,
      requiresOnboarding: false,
      permission: undefined,
    },
  },
  {
    ...homeRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: true,
      requiresActiveSubscription: true,
      requiresSuccessfulProvision: true,
      requiresOnboarding: true,
      permission: undefined
    },
    children: [
      {
        name: homeRoute.name,
        path: 'all-financial-statements',
        alias: ['', '/', 'all-financial-statements/'],
        redirect: (to: RouteLocation): RouteLocationRaw => {
          const tenantId = useAuthState().getTenantId()

          if (tenantId) {
            return allFinancialStatementsRoute.route({ tenantId: tenantId })
          } else {
            return organizationsRoute.route()
          }
        }
      },
      {
        name: 'financial-statements2',
        path: 'organization/:tenantId/',
        alias: 'organization/:tenantId/',
        redirect: (to: RouteLocation): RouteLocationRaw => allFinancialStatementsRoute.route({ tenantId: to.params.tenantId.toString() })
      },
      {
        name: 'financial-statements',
        path: 'financial-statements',
        alias: 'financial-statements/',
        redirect: (to: RouteLocation): RouteLocationRaw => allFinancialStatementsRoute.route({ tenantId: to.params.tenantId.toString() })
      },
      {
        ...allFinancialStatementsRoute
      },
      {
        ...newFinancialStatementRoute
      }
    ]
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  },
  {
    ...financialStatementRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      requiresTenant: true,
      requiresActiveSubscription: true,
      requiresSuccessfulProvision: true,
      requiresOnboarding: true,
      permission: undefined
    },
    children: [
      {
        name: financialStatementRoute.name,
        path: '',
        redirect: (to: RouteLocation): RouteLocationRaw => {
          const tenantId = to.params.tenantId.toString()
          const uuid = to.params.uuid.toString()

          return statusRoute.route({ tenantId: tenantId, uuid: uuid })
        }
      },
      {
        ...supportRoute,
      },
      {
        ...statusRoute,
      },
      {
        ...backgroundRoute,
        children: [
          {
            name: backgroundRoute.name,
            path: '',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              const tenantId = to.params.tenantId.toString()
              const uuid = to.params.uuid.toString()

              return draftRoute.route({ tenantId: tenantId, uuid: uuid })
            }
          },
          {
            ...draftRoute
          },
          {
            ...questionsRoute
          },
        ]
      },
      {
        ...accountingRoute,
        children: [
          {
            name: accountingRoute.name,
            path: '',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              const tenantId = to.params.tenantId.toString()
              const uuid = to.params.uuid.toString()

              return trialBalanceRoute.route({ tenantId: tenantId, uuid: uuid })
            }
          },
          {
            ...trialBalanceRoute
          },
          {
            ...transactionRoute
          }
        ]
      },
      {
        ...annualStatementRoute,
        children: [
          {
            name: annualStatementRoute.name,
            path: '',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              const tenantId = to.params.tenantId.toString()
              const uuid = to.params.uuid.toString()

              return annualStatementResultRoute.route({ tenantId: tenantId, uuid: uuid })
            }
          },
          {
            ...annualStatementResultRoute
          },
          {
            ...annualStatementBalanceRoute
          },
          {
            ...annualStatementBoardRoute
          },
          {
            ...annualStatementNotesRoute
          },
          {
            ...annualStatementSubmissionRoute
          }
        ]
      },
      {
        ...taxReturnBaseRoute,
        children: [
          {
            name: 'tax-return-redirect',
            path: '',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              const tenantId = to.params.tenantId.toString()
              const uuid = to.params.uuid.toString()

              return businessInformationRoute.route({ tenantId: tenantId, uuid: uuid })
            }
          },
          {
            ...businessInformationRoute
          },
          {
            ...taxReturnRoute
          },
          {
            ...taxReturnSubmissionRoute
          }
        ]
      },
      {
        ...financialStatementAssetRegistryRoute,
        children: [
          {
            name: financialStatementAssetRegistryRoute.name,
            path: '',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              const tenantId = to.params.tenantId.toString()
              const uuid = to.params.uuid.toString()

              return finstaAllAssetsRoute.route({ tenantId: tenantId, uuid: uuid })
            }
          },
          {
            ...finstaAllAssetsRoute
          },
          {
            ...finstaAllProfitAndLossAccountsRoute
          },
          {
            ...finstaAllBalanceGroupsRoute
          }
        ]
      },
      {
        ...finregRoute,
        children: [
          {
            name: finregRoute.name,
            path: '',
            redirect: (to: RouteLocation): RouteLocationRaw => {
              const tenantId = to.params.tenantId.toString()
              const uuid = to.params.uuid.toString()

              return finregStocksRoute.route({ tenantId: tenantId, uuid: uuid })
            }
          },
          {
            ...finregStocksRoute
          }
        ]
      }
    ]
  },
  {
    ...profileRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      permission: undefined
    },
    children: [
      {
        name: profileRoute.name,
        path: '',
        redirect: myProfileRoute.route(),
      },
      {
        ...myProfileRoute
      },
      {
        ...profileSettingsRoute
      },
    ]
  },
  {
    ...signoutRoute,
    meta: {
      requiresAnonymous: true,
      requiresAuth: false,
      permission: undefined
    },
  },
  {
    ...adminRoute,
    meta: {
      requiresAnonymous: false,
      requiresAuth: true,
      permission: undefined
    },
  }
]

export default routes
