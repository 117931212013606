import { useFirebaseAuth, useFirebaseFirestore } from '@/plugins/firebase'
import { FirestoreCollections, FirestoreSession } from '@/plugins/firebase/types'
import useIdPortenService from '@/services/IdPorten'
import useAuthState from '@/store/UseAuthState'
import { collection, doc, onSnapshot } from 'firebase/firestore'
import { watch } from 'vue'
import URLResolver from '@/services/URLResolver'

const useContaSession = () => {

  let clearSessionSubscription: Function | undefined

  const onSessionUpdate = async (session?:FirestoreSession) => {
    if (session && session.endedAt) {
      await useIdPortenService().logout()
      await useFirebaseAuth().signOut(false)
      window.location.href = URLResolver.resolveSignoutLocation()
    }
  }

  const startWatchingSession = (userId: string, sessionId: string, onUpdate: (session: FirestoreSession|undefined) => void) => {
    if (clearSessionSubscription) {
      clearSessionSubscription()
    }

    const docRef = doc(
      collection(
        useFirebaseFirestore().firestore,
        `${FirestoreCollections.users}/${userId}/${FirestoreCollections.sessions}`
      ),
      sessionId
    )

    const subscription = onSnapshot(docRef, (doc) => {
      const session = useFirebaseFirestore().readSession(doc)
      onUpdate(session)
    }, () => {
      onUpdate(undefined)
    })

    clearSessionSubscription = () => {
      subscription()
      onUpdate(undefined)
    }
  }

  watch(() => useAuthState().user.value, (user) => {
    if (!user) {
      return
    }

    const userId = user.uid
    const sessionId = user.claims.sessionId
    startWatchingSession(userId, sessionId, onSessionUpdate)
  })

}

export default useContaSession
