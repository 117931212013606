import { getApp } from 'firebase/app'
import { CollectionReference, DocumentData, Firestore, getFirestore, getDocs, collection, query, where, DocumentSnapshot, Timestamp, doc } from 'firebase/firestore'
import { FirestoreCollections, FirestoreOrg, FirestoreSession, FirestoreSessionField, FirestoreTimestamp, } from '@/plugins/firebase/types'

const userOrgsPath = (userId: string) => `${FirestoreCollections.users}/${userId}/${FirestoreCollections.organizations}`

export default class FirebaseFirestore {
  firestore!: Firestore

  init () {
    const app = getApp()

    this.firestore = getFirestore(app)
  }

  getUserCollection = () => {
    return collection(this.firestore, FirestoreCollections.users)
  }

  getUserDoc = (userId:string) => {
    return doc(this.getUserCollection(), userId)
  }

  getOrgCollection = (userId: string) => {
    return collection(this.firestore, userOrgsPath(userId))
  }

  getOrgs = async (userId: string): Promise<FirestoreOrg[] | []> => {
    try {
      const orgCollectionReference = await this.getOrgCollection(userId)

      const orgSnapshot = await getDocs(orgCollectionReference)

      const orgs: FirestoreOrg[] = []

      orgSnapshot.forEach((doc: any) => orgs.push({ ...doc.data(), id: doc.id }))

      if (!orgs || !orgs.length) return []

      return orgs
    } catch (error: any) {
      console.error('get orgs error: ', error)
      return []
    }
  }

  getOrgByOrgId = async (userId: string, orgId: string): Promise<FirestoreOrg | undefined> => {
    try {
      if (!userId || !orgId) return undefined

      const orgCollectionReference: CollectionReference<DocumentData> = await collection(this.firestore, userOrgsPath(userId))

      const orgQuery = await query(orgCollectionReference, where('organizationId', '==', orgId))

      const orgSnapshot = await getDocs(orgQuery)

      const orgs: FirestoreOrg[] = []

      orgSnapshot.forEach((doc: any) => orgs.push({ ...doc.data(), id: doc.id }))

      if (!orgs || !orgs.length) return undefined

      return orgs[0]
    } catch (error: any) {
      console.error('get org by orgId error: ', error)
      return undefined
    }
  }

  readSession = (snapshot?:DocumentSnapshot<DocumentData>) => {
    if (snapshot?.exists) {
      const startedAt:FirestoreTimestamp|undefined = snapshot.get(FirestoreSessionField.startedAt)
      const endedAt:FirestoreTimestamp|undefined = snapshot.get(FirestoreSessionField.endedAt)

      const org:FirestoreSession = {
        id: snapshot.id,
        ipAddress: snapshot.get(FirestoreSessionField.ipAddress),
        startedAt: startedAt ? new Timestamp(startedAt.seconds, startedAt.nanoseconds).toDate().getTime() : undefined,
        endedAt: endedAt ? new Timestamp(endedAt.seconds, endedAt.nanoseconds).toDate().getTime() : undefined,
        userAgent: snapshot.get(FirestoreSessionField.userAgent),
        userId: snapshot.get(FirestoreSessionField.userId),
      }
      return org
    }
    return undefined
  }
}
