import { allFinancialStatementsRoute, registeredOrganizationRoute } from '@/router/paths'

export enum ApiPath {
  patron = '/patron',
  brreg = '/integration/brreg',
}

/**
 * TODO@kristian - 2023-06-12: Keep only one of ServerBasePathResolver and URLResolver
 */

export default class URLResolver {

  static resolveApiUrl = () => {
    const { host, protocol } = window.location
    const hostBase = URLResolver.resolveBaseHost(host, true)
    if (!hostBase) {
      return 'http://api.tritt.test'
    }

    return `${protocol}//api.${hostBase}`
  }

  static getServicesUrl = (tid: string) => {
    return `${URLResolver.resolvePatronOrgLocation(tid)}/services`
  }

  static getCompanySettingsUrl = (tid: string) => {
    return `${URLResolver.resolvePatronOrgLocation(tid)}`
  }

  static resolvePatronProfilePage = () => {
    const { protocol, host } = window.location

    const hostBase = URLResolver.resolveBaseHost(host)

    return `${protocol}//start.${hostBase}/profile`
  }

  static resolvePatronNewOrgPage = () => {
    const { protocol, host } = window.location

    const hostBase = URLResolver.resolveBaseHost(host)

    const redirectUrl = `${protocol}//arsoppgjor.${hostBase}${registeredOrganizationRoute.path}`

    const url = new URL(`${protocol}//start.${hostBase}/register-organization`)

    url.searchParams.append('redirectUrl', encodeURIComponent(redirectUrl))

    return url.href
  }

  static resolvePatronProductPage = (tenantId: string) => {
    const { protocol, host } = window.location

    const hostBase = URLResolver.resolveBaseHost(host)

    const redirectUrl = `${protocol}//arsoppgjor.${hostBase}${allFinancialStatementsRoute.route({ tenantId: tenantId })}`

    const url = new URL(`${protocol}//start.${hostBase}/organization/${tenantId}/product/financialStatement`)

    url.searchParams.append('redirectUrl', encodeURIComponent(redirectUrl))

    return url.href
  }

  static resolvePatronOrgLocation = (tenantId: string = '') => {
    const { protocol, host } = window.location

    const hostBase = URLResolver.resolveBaseHost(host)

    let patronLocation = `${protocol}//start.${hostBase}/organization`

    if (tenantId) {
      patronLocation = patronLocation + '/' + tenantId
    }

    return patronLocation
  }

  static resolveSigninLocation = () => {
    const { protocol, host } = window.location

    const hostBase = URLResolver.resolveBaseHost(host)
    const searchParams = new URLSearchParams({
      redirectUrl: window.location.href
    })
    return `${protocol}//start.${hostBase}/signin?${searchParams.toString()}`
  }

  static resolveSignoutLocation = () => {
    const { protocol, host } = window.location

    const hostBase = URLResolver.resolveBaseHost(host)

    const patronLocation = `${protocol}//arsoppgjor.${hostBase}/signout`

    return patronLocation
  }

  static resolveBaseHost = (host: string, replaceHost: boolean = false) => {
    const match = host.match(/(arsoppgjor).(.*)/)
    if (match != null && match.length >= 3) {
      if (replaceHost && match[2].includes('conta')) {
        match[2] = match[2].replace('conta', 'tritt')
      }
      return match[2]
    }
    console.error('ERROR: This hostname will not work for cluster based configuration.')
    return undefined
  }

  static getSubHost (subDomain:string) {
    return URLResolver.replaceHost(window.location.host, subDomain)
  }

  static replaceHost (host:string, subDomain:string) {
    const parts = host.split('.')
    parts[0] = subDomain
    return parts.join('.')
  }

  static getProtocol() {
    return window.location.protocol
  }
}
