import { Configuration, Middleware, RequestArgs } from '@/generated/finsta-client-ts'
import useAuthState from '@/store/UseAuthState'

export default class RequestInterceptor extends Configuration {
  // eslint-disable-next-line no-use-before-define
  private static config: RequestInterceptor

  private constructor () {
    const middleware: Middleware[] = [{
      pre (request: RequestArgs): RequestArgs {
        const { getTenantId, getOrgNo, getAccessToken } = useAuthState()

        const organizationId = getTenantId()
        const orgNo = getOrgNo()
        const accessToken = getAccessToken()

        return {
          ...request,
          headers: {
            ...(request.headers ?? {}),
            ...(organizationId ? { OrganizationId: organizationId } : {}),
            ...(orgNo ? { 'X-ORG-NO': orgNo } : {}),
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
          }
        }
      }
    }]

    super({ middleware })
  }

  public static get Instance () {
    return RequestInterceptor.config || (RequestInterceptor.config = new this())
  }
}
