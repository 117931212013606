const isDigits = (str: string) => {
  if (str && str.length) {
    return !isNaN(parseFloat(str))
  }
  return false
}

export default class EnumHelper {

  static parse<T extends {}> (e: T, value:T[keyof T]):T[keyof T]|undefined {
    if (EnumHelper.getObjValues(e).includes(value)) {
      return value
    }
    return undefined
  }

  static getNamesAndValues<T extends {}> (e: T) {
    return EnumHelper.getObjKeys(e).map((n) => ({ name: n, value: e[n] }))
  }

  static getObjKeys<T extends {}> (e: T): (keyof T)[] {
    return (Object.keys(e) as (keyof T)[]).filter((k) => !isDigits(k.toString()))
  }

  static getObjValues<T extends {}> (e: T) {
    return EnumHelper.getObjKeys(e).map((k) => e[k])
  }

}
