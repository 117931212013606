export class RequestError extends Error {
  statusCode:number

  statusText:string

  constructor(message:string, statusText:string, statusCode:number) {
    super(message)
    this.name = 'RequestError'
    this.statusText = statusText
    this.statusCode = statusCode
  }
}
