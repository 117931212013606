export default class Timer {

  private timer: any

  private callback: () => void

  private delay: number

  constructor (callback: () => void, delay: number) {
    this.callback = callback
    this.delay = delay
    this.timer = setTimeout(callback, delay)
  }

  pause () {
    this.stop()
  }

  resume () {
    this.timer = setTimeout(this.callback, this.delay)
  }

  stop () {
    clearTimeout(this.timer)
  }
}
