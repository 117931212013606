import { AjaxRequest } from 'rxjs/ajax'
import { useToast } from '@/plugins/toast'
import { toProblemMessage } from '@/utilities/error/ProblemUtils'
import { Problem } from '@/generated/finsta-client-ts'
import { $t } from '@/plugins/i18n/i18n'
import useErrorLogging from '@/composables/useErrorLogging'
import { Dictionary } from '@/types'
import { ToastMessage } from '@/plugins/toast/types'

export const statusCodes: { [key: number]: string; } = {
  200: 'OK',
  201: 'Created',
  202: 'Accepted',
  203: 'Non-Authoritative Information',
  204: 'No Content',
  205: 'Reset Content',
  206: 'Partial Content',
  300: 'Multiple Choices',
  301: 'Moved Permanently',
  302: 'Found',
  303: 'See Other',
  304: 'Not Modified',
  305: 'Use Proxy',
  306: 'Unused',
  307: 'Temporary Redirect',
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  406: 'Not Acceptable',
  407: 'Proxy Authentication Required',
  408: 'Request Timeout',
  409: 'Conflict',
  410: 'Gone',
  411: 'Length Required',
  412: 'Precondition Required',
  413: 'Request Entry Too Large',
  414: 'Request-URI Too Long',
  415: 'Unsupported Media Type',
  416: 'Requested Range Not Satisfiable',
  417: 'Expectation Failed',
  418: 'I\'m a teapot',
  429: 'Too Many Requests',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
  505: 'HTTP Version Not Supported',
}

export type SimpleError = { message: string }
export type ApiError = {
  response?: SimpleError | Problem
  request?: AjaxRequest
  status: number
} & Error

export function isApiError (value: any): value is ApiError {
  return 'response' in value
    && 'request' in value
    && 'status' in value
}

export class AppError extends Error {
  code: string

  constructor (code: string, name: string | undefined, message: string | undefined) {
    super(message)
    this.code = `app-error.${code}`
    this.name = name ?? 'unknown'

    Object.setPrototypeOf(this, AppError.prototype)
  }
}

const containsKeys = <T extends Object> (obj: any, ...keys: (keyof T)[]) => {
  return keys.every((k) => k in obj)
}

const isProblemJson = (obj: Object) => {
  return containsKeys<Problem>(obj, 'code', 'detail')
}

export const displayError = (error: AppError | Error | any, component: any, info: any) => {
  if (error instanceof AppError || error instanceof Error) {
    useToast().showError({
      title: $t('general.system-error'),
      message: error.message
    })
  } else {
    useToast().showError({
      title: $t('general.error'),
      message: error
    })
  }

  useErrorLogging().logError(error, component, info)
}

export const displayErrorWithInfoMessage = (error: AppError | Error | any, component: any, info: any) => {
  if (error instanceof AppError || error instanceof Error) {
    useToast().showError({
      title: $t('general.system-error'),
      externalServiceName: info.externalServiceName,
      message: info.externalMessage
    } as ToastMessage)
  } else {
    useToast().showError({
      title: $t('general.error'),
      message: error
    })
  }

  useErrorLogging().logError(error, component, info)
}

export const handleApiError = (error: ApiError) => {

  const headers = error.request?.headers as Dictionary<any> | undefined
  const orgIdHeader = headers && headers.OrganizationId

  const info = {
    message: error.message,
    method: error.request?.method,
    url: error.request?.url,
    status: error.status,
    response: error.response,
    organizationNo: orgIdHeader
  }

  displayError(createAppError(error), undefined, info)
}

export const handleApiErrorWithInfoMessage = (error: ApiError) => {

  const headers = error.request?.headers as Dictionary<any> | undefined
  const orgIdHeader = headers && headers.OrganizationId
  const info = {
    message: error.message,
    method: error.request?.method,
    url: error.request?.url,
    status: error.status,
    response: error.response,
    organizationNo: orgIdHeader,
    // @ts-ignore
    externalMessage: error.response?.info?.externalMessage,
    // @ts-ignore
    externalServiceName: error.response?.info?.externalServiceName
  }

  displayErrorWithInfoMessage(createAppError(error), undefined, info)
}

const createAppError = (error: ApiError) => {
  const response = error.response

  if (!response) {
    if (error.status === 403) {
      return new AppError(statusCodes[error.status], String(error.status), '403 - Mangler tilgang')
    }
    return new AppError(statusCodes[error.status], String(error.status), error.message)
  }

  if (isProblemJson(response)) {
    const problemJson = response as Problem

    return new AppError(
      String(problemJson.code),
      statusCodes[error.status] + (!problemJson.title ? '' : ' - ' + problemJson.title),
      toProblemMessage(problemJson)
    )
  }

  return new AppError(statusCodes[error.status], String(error.status), error.message)
}
