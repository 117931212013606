import { allGuardsInOne } from '@/router/guards'
import routes from '@/router/routes'
import { createRouter, createWebHistory, Router } from 'vue-router'

function buildRouter(): Router {
  const createdRouter = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes
  })

  createdRouter.beforeEach(allGuardsInOne)

  return createdRouter
}

let router: Router

if (import.meta.hot) {
  /* HMR enabled */

  if (import.meta.hot.data.router) {
    router = import.meta.hot.data.router
  } else {
    router = buildRouter()
    import.meta.hot.data.router = router
  }

} else {
  /* HMR disabled */

  router = buildRouter()
}

export const getRouter = () => router
