import { applicationSettingsStore } from '@/store/applicationSettings.store'
import { initializeLoggers } from '@/composables/useErrorLogging'
import { EventType } from '@/plugins/event/types'
import { useEventService } from '@/plugins/event/useEventService'
import { initializeFirebase } from '@/plugins/firebase'
import { firebaseConfigStore } from '@/plugins/firebase/config/firebaseConfig.store'
import i18n from '@/plugins/i18n/i18n'
import '@/plugins/input-mask/style/style.css'
import { initializeFinsta } from '@/services/initialization/InitializeFinsta'
import '@/utilities/font-awesome'
import SplashScreen from '@/views/SplashScreen.vue'
import '@fontsource/fira-code/latin.css'
import '@fontsource/fira-sans/latin.css'
import '@fontsource/open-sans/latin.css'
// eslint-disable-next-line import/no-unresolved
import 'uno.css'
import '@tritt/components/dist/theme.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import './css/tailwind.css'

const pinia = createPinia()

useEventService().clearEventSubscriptions(EventType.APPLICATION_READY)

const loadingApp = createApp(SplashScreen)
loadingApp
  .use(i18n)
  .use(pinia)
  .mount('#app')

useEventService().addEventSubscription(
  EventType.APPLICATION_READY,
  async () => {
    const mainApp = await import('./mainApp')
    const mainAppInstance = await mainApp.createApp(pinia)

    setTimeout(() => {
      loadingApp.unmount()
      mainAppInstance.mount('#app')
    }, 1000)
  },
  false
)

const loadRemoteSettings = async () => {
  await firebaseConfigStore().loadFirebaseConfig()
  await applicationSettingsStore().loadEnvironmentConfig()
}

initializeFinsta()
loadRemoteSettings().then(async () => {
  initializeLoggers()
  await initializeFirebase()
})
