// @ts-nocheck

import { createI18n } from 'vue-i18n'
import { accountsNB, accountsEN, accountMapper, accountGroups } from '@tritt/conta-bookkeeping-accounts'
import { Remark } from '@/generated/finsta-client-ts'

import componentsLibEN from '@tritt/components/dist/assets/localization/components.en.json'
import componentsLibNB from '@tritt/components/dist/assets/localization/components.nb.json'

import en from './en/en.json'
import remarksEn from './en/remarks-en.json'
import problemsEn from './en/problems-en.json'
import nonStandardAccountsEn from './en/non-standard-accounts-en.json'
import postsEn from './en/posts-en.json'
import taxEn from '../../views/tax-return/tax-return/i18n/tax-en.json'
import annstaEn from '../../views/annual-statement/i18n/annsta-en.json'
import finregEn from '../../views/finreg/i18n/finreg-en.json'
import patronEn from '../../views/patron/i18n/patron-en.json'
import componentsEn from '../../components/i18n/components-en.json'
import backgroundEn from '../../views/background/i18n/background-en.json'
import cgEn from '../../views/tax-return/business-information/content/company-group/i18n/cg-en.json'

import nb from './nb/nb.json'
import remarksNb from './nb/remarks-nb.json'
import problemsNb from './nb/problems-nb.json'
import postsNb from './nb/posts-nb.json'
import nonStandardAccountsNb from './nb/non-standard-accounts-nb.json'
import taxNb from '../../views/tax-return/tax-return/i18n/tax-nb.json'
import annstaNb from '../../views/annual-statement/i18n/annsta-nb.json'
import finregNb from '../../views/finreg/i18n/finreg-nb.json'
import patronNb from '../../views/patron/i18n/patron-nb.json'
import componentsNb from '../../components/i18n/components-nb.json'
import backgroundNb from '../../views/background/i18n/background-nb.json'
import cgNb from '../../views/tax-return/business-information/content/company-group/i18n/cg-nb.json'

const datetimeFormats = {
  EN: {
    'short-date': {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    'long-date': {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    'long-ts': {
      year: 'numeric', month: 'long', day: 'numeric',
      weekday: 'short', hour: 'numeric', minute: 'numeric'
    }
  },
  NB: {
    'short-date': {
      year: 'numeric', month: 'short', day: 'numeric'
    },
    'long-date': {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    'long-ts': {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: 'numeric', minute: 'numeric'
    }
  }
}

const i18n = createI18n({
  allowComposition: true,
  locale: 'NB',
  fallbackLocale: 'NB',
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  datetimeFormats: datetimeFormats,
  messages: {
    EN: {
      ...en,
      cmp: componentsEn,
      components: componentsLibEN,
      bg: backgroundEn,
      tax: taxEn,
      annsta: annstaEn,
      finreg: finregEn,
      cogreg: cgEn,
      patron: patronEn,
      post: postsEn,
      remark: remarksEn,
      problem: problemsEn,
      account: {
        ...accountMapper.mapToAccountInformation(accountsEN),
        ...nonStandardAccountsEn
      },
      'account-groups': accountGroups.EN
    },
    NB: {
      ...nb,
      cmp: componentsNb,
      components: componentsLibNB,
      bg: backgroundNb,
      tax: taxNb,
      annsta: annstaNb,
      finreg: finregNb,
      cogreg: cgNb,
      patron: patronNb,
      post: postsNb,
      remark: remarksNb,
      problem: problemsNb,
      account: {
        ...accountMapper.mapToAccountInformation(accountsNB),
        ...nonStandardAccountsNb
      },
      'account-groups': accountGroups.NB
    }
  }
})

export const $t = (key: string, params: any = undefined) => {
  if (params) {
    return i18n.global.t(key, params)
  } else {
    return i18n.global.t(key)
  }
}
export const $te = (key: string) => i18n.global.te(key)
export const $d = (key: string) => i18n.global.d(key)

export const buildRemarkMessage = (r: Remark) => {
  const params = {
    ...r.info,
    field: r.field,
    translatedField: $t(`remark.field.${r.field}`),
    // @ts-ignore
    accountNos: r.info?.accountNos?.join(',')
  }

  return $t(`remark.code.${r.code}`, params)
}

export default i18n
