import URLResolver from '@/services/URLResolver'
import { useFirebaseFunctions } from '@/plugins/firebase'
import FirebaseFunctions from '@/plugins/firebase/functions/FirebaseFunctions'
import usePatronService from '@/services/patron/PatronService'
import useAuthState from '@/store/UseAuthState'
import { Auth, GoogleAuthProvider, IdTokenResult, User, getAuth, signOut, signInWithCustomToken } from 'firebase/auth'
import { getApp } from 'firebase/app'
import { useEventService } from '@/plugins/event/useEventService'
import { EventType } from '@/plugins/event/types'
import store from '@/store'
import { ExtendedUser, UserRole } from '@/types'
import { handleError } from '@/plugins/firebase/auth/FirebaseAuthErrorHandler'
import { Ref, ref } from 'vue'
import { getActiveTenant } from '@/services/tenant/TenantService'
import { useRouter } from '@/router/useRouter'
import { allFinancialStatementsRoute } from '@/router/paths'

export default class FirebaseAuth {
  private auth!: Auth
  private functions!: FirebaseFunctions

  private googleAuthProvider!: GoogleAuthProvider
  private canSearch!: Ref<boolean>

  init (functions: FirebaseFunctions) {
    const app = getApp()
    this.canSearch = ref(false)
    this.auth = getAuth(app)
    this.functions = functions
    this.googleAuthProvider = new GoogleAuthProvider()

    this.auth.onIdTokenChanged((user) => {
      this.onIdTokenChanged(user)
    })

    window.addEventListener('beforeunload', async () => {
      indexedDB.deleteDatabase('firebaseLocalStorageDb')
    })
  }

  /* eslint-disable no-useless-return, @typescript-eslint/no-unused-vars */
  onIdTokenChanged = async (user: User|null) => {
    if (!user) {
      // No user exists -> try to sign in with custom token from SSO
      await this.trySignInWithCustomToken()
      return
    }

    const { data, error } = await useFirebaseFunctions().hasSingleSignOnSession(false)

    if (data?.sessionId) {

      // Existing session id found -> Get session id from token
      const fbaSessionId = await user.getIdTokenResult(false).then((decoded) => decoded.claims.sessionId)

      if (data.sessionId === fbaSessionId) {
        // Same session -> all auth states valid
        await this.onAuthStateChanged(user)
        return
      } else {
        // Different session -> Try to sign in with custom token from SSO
        await this.trySignInWithCustomToken()
        return
      }
    } else {
      // No session id found -> User not logged in
      await this.signOut(false).then(() => window.location.href = URLResolver.resolveSigninLocation())
      return
    }
  }
  /* eslint-enable no-useless-return, @typescript-eslint/no-unused-vars */

  trySignInWithCustomToken = async () => {
    try {
      const ssoResult = await useFirebaseFunctions().hasSingleSignOnSession(true)
      if (ssoResult.data?.token) {
        return await this.signInWithCustomToken(ssoResult.data.token).then(credential => this.onAuthStateChanged(credential.user))
      } else {
        return await this.onAuthStateChanged(null)
      }
    } catch (error) {
      console.error('SignInWithCustomTokenError', error)
      window.location.href = URLResolver.resolveSignoutLocation()
      return useAuthState().setUser(undefined)
    }
  }

  signInWithCustomToken = (loginToken: string) => {
    return signInWithCustomToken(this.auth, loginToken)
  }

  onAuthStateChanged = async (user: User | null): Promise<void> => {
    await this.processUser(user, false)

    this.ensureTenantId()

    await usePatronService().updatePatronOverview()

    await this.isAdminOrSupport()

    useEventService().publishEvent(EventType.AUTH_READY)
  }

  ensureTenantId = () => {
    const params = useRouter().getParams()
    const tenantId = useAuthState().getTenantId()

    if (params.tenantId && tenantId && params.tenantId !== tenantId) {
      window.location.href = window.location.origin + allFinancialStatementsRoute.route({ tenantId: tenantId })
    }
  }

  userHasSearchPermissions = () => {
    return this.canSearch
  }

  getRoles = async () => {
    const user = this.auth.currentUser
    if (!user) return []
    const idTokenResult: IdTokenResult = await user.getIdTokenResult(false)
    return idTokenResult.claims.tritt?.rls || []
  }

  refreshToken = async (): Promise<void> => {
    await this.processUser(this.auth.currentUser, true)
  }

  isAdminOrSupport = async() : Promise<void> => {
    const roles = await this.getRoles()
    this.canSearch.value = roles.includes(UserRole.support) || roles.includes(UserRole.admin) || roles.includes(UserRole.developer)
  }

  refreshStoreToken = async () => {
    const token = useAuthState().getAccessToken() || ''
    const parsedToken = JSON.parse(atob(token.split('.')[1]))
    if (parsedToken.exp - Math.floor(Date.now() / 1000) < 0) {
      await this.processUser(this.auth.currentUser, false)
    }
  }

  processUser = async (user: User | null, forceRefreshToken: boolean): Promise<ExtendedUser | null> => {
    if (!user) {
      useAuthState().setUser(undefined)
      return null
    }

    const idTokenResult: IdTokenResult = await user.getIdTokenResult(forceRefreshToken)
    const accessToken = idTokenResult.token
    const claims = idTokenResult.claims

    const extendedUser: ExtendedUser = { ...user, accessToken, claims }

    useAuthState().setUser(extendedUser)

    if (useAuthState().getTenantId()) {
      useAuthState().setActiveTenant(
        await getActiveTenant(
          useAuthState().getTenantId()!,
          useAuthState().uid.value
        )
      )
    }

    return extendedUser
  }

  getUserIdToken = () => {
    return this.auth.currentUser?.getIdToken()
  }

  signOut = async (includeSsoSession: boolean = true): Promise<void> => {
    try {
      if (includeSsoSession) {
        await this.functions.logoutSingleSignOnSession()
      }
      await signOut(this.auth)
      await store.dispatch('setDefaultState')
    } catch (error: any) {
      handleError(error)
    }
  }
}
