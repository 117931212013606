import { handleError } from '@/plugins/firebase/auth/FirebaseAuthErrorHandler'
import { firebaseConfigStore } from '@/plugins/firebase/config/firebaseConfig.store'
import { RequestError } from '@/plugins/firebase/functions/RequestError'
import useAuthState from '@/store/UseAuthState'
import { UpdatePrivilegesCommand } from '@/types'
import { FirebaseError, getApp } from 'firebase/app'
import { Functions, getFunctions, httpsCallable } from 'firebase/functions'

export type FirebaseLoginResult = {
  token?:string
  status?:string
  sessionId?:string
}

const firebaseFunctionsRegion = 'europe-west1'

export type PromiseData<T, U> = {data?:T, error?:U}

async function handlePromise<T, U> (promise:Promise<T>):Promise<PromiseData<T, U>> {
  return promise
    .then((data) => { return { data, error: undefined } })
    .catch((error) => Promise.resolve({ data: undefined, error }))
}

enum FirebaseFunctionName {
  setActiveTenant = 'claims-selectActiveTenant',
  clearActiveTenant = 'claims-clearActiveTenant',
  userOrganizationSearch = 'searchIndexes-userOrganizations-search',
  gainPrivilegesOverTenant = 'claims-gainPrivilegesOverTenant',
  dropPrivilegesOverTenant = 'claims-dropPrivilegesOverTenant',
  selectActiveTenant = 'claims-selectActiveTenant',
  addRoleToUser = 'userRoles-addRoleToUser',
  removeRoleFromUser = 'userRoles-removeRoleFromUser',
  findUserProfile = 'userProfiles-findUserProfile',
  searchUserOrganizations = 'searchIndexes-userOrganizations-search',
  reindexUserOrganizations = 'searchIndexes-userOrganizations-reindex',
  userProfilesCreateUserProfile = 'userProfiles-createUserProfile',
  addOwnerRoleToUser = 'claims-addOwnerRoleToUser',
  removeOwnerRoleFromUser = 'claims-removeOwnerRoleFromUser',
  authenticationSessionHandler = 'authentication-sessionHandler',
  authenticationMfa = 'authentication-mfa',
  authenticationV2 = 'authentication-v2auth'
}

const firebaseFunctionPath: Record<FirebaseFunctionName, string> = {
  [FirebaseFunctionName.gainPrivilegesOverTenant]: '/claims/gain-privileges',
  [FirebaseFunctionName.dropPrivilegesOverTenant]: '/claims/drop-privileges',
  [FirebaseFunctionName.selectActiveTenant]: '/claims/select-active',
  [FirebaseFunctionName.clearActiveTenant]: '/claims/clear-active',
  [FirebaseFunctionName.addRoleToUser]: '/claims/add-role',
  [FirebaseFunctionName.removeRoleFromUser]: '/claims/remove-role',
  [FirebaseFunctionName.addOwnerRoleToUser]: '/claims/add-owner-role',
  [FirebaseFunctionName.removeOwnerRoleFromUser]: '/claims/remove-owner-role',
  [FirebaseFunctionName.findUserProfile]: '/users/find-profile',
  [FirebaseFunctionName.userProfilesCreateUserProfile]: '/users/create-profile',
  [FirebaseFunctionName.searchUserOrganizations]: '/tenants/search',
  [FirebaseFunctionName.reindexUserOrganizations]: '/tenants/reindex',
  [FirebaseFunctionName.authenticationSessionHandler]: '/auth/session',
  [FirebaseFunctionName.authenticationMfa]: '/auth/mfa',
  [FirebaseFunctionName.authenticationV2]: '/v2/auth',
}

type CallableParams = {
  functions: Functions
  functionName: FirebaseFunctionName
  method?: string
  headers?: Record<string, string>
  query?: Record<string, string>
  token?: string
  tenantId?: string
}

export const customHttpsCallable = <T>({ functions, functionName, method = 'POST', headers, query, token, tenantId }: CallableParams
) => {
  return async (data?: any) => {
    const url = constructFunctionsUrl(functions, functionName, query)

    const authHeaders:Record<string, string> = {}
    if (token) {
      authHeaders.Authorization = `Bearer ${token}`
    } if (tenantId) {
      authHeaders.tenantId = tenantId
    }

    const requestBody = data ? { data: data } : data

    const response = await fetch(url, {
      method,
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...authHeaders,
        ...headers,
      },
      body: JSON.stringify(requestBody)
    })
    if (!response.ok) {
      const isJSON = response.headers.get('content-type')?.includes('application/json')
      if (isJSON) {
        const data = await response.json()
        throw data
      }
      const text = await response.text()
      throw new RequestError(text, response.statusText, response.status)
    } else {
      const data = await response.json()
      return data as T | undefined
    }
  }
}

const resolvePath = (functionName: FirebaseFunctionName): string | undefined => {
  return firebaseFunctionPath[functionName] ?? 'function-name-unknown'
}

export const constructFunctionsUrl = (functions: Functions, functionName: FirebaseFunctionName, query: Record<string, string> = {}) => {
  const queryParams = new URLSearchParams(query).toString()
  const queryPart = queryParams.length > 0 ? `?${queryParams}` : ''

  if (firebaseConfigStore().useFirebaseEmulator) {
    return `http://${firebaseConfigStore().firebaseEmulatorHost}/cloud-functions/${functions.app.options.projectId}/${functions.region}/${functionName}${queryPart}`
  }

  const localFunctionPath = resolvePath(functionName)
  return `${location.origin}${localFunctionPath}${queryPart}`
}

export default class FirebaseFunctions {
  private functions!: Functions

  init () {
    const app = getApp()

    if (app.options.projectId === 'demo-tritt') {
      this.functions = getFunctions(app)
    } else {
      this.functions = getFunctions(app, firebaseFunctionsRegion)
    }
  }

  accessFunction = async (data: Object, functionName: string) => {
    const callable = httpsCallable(this.functions, functionName)

    const result = await callable(data)
      .then((res: any) => res)
      .catch(handleError)

    return result
  }

  hasSingleSignOnSession = async (includeToken: boolean = false) => {
    return handlePromise<FirebaseLoginResult|undefined, FirebaseError>(
      customHttpsCallable<FirebaseLoginResult>({
        functions: this.functions,
        functionName: FirebaseFunctionName.authenticationV2,
        method: 'GET',
        query: { includeToken: String(includeToken) } }
      )()
    )
  }

  logoutSingleSignOnSession = async () => {
    const url = constructFunctionsUrl(this.functions, FirebaseFunctionName.authenticationSessionHandler)
    await fetch(url, {
      method: 'DELETE',
      credentials: 'same-origin',
    })
  }

  searchUserOrganizations = async (query: string, page: number = 0, hitsPerPage: number = 10) => {
    return this.accessFunction({ query, page, hitsPerPage }, FirebaseFunctionName.userOrganizationSearch)
  }

  gainPrivilegesOverTenant = async (command: UpdatePrivilegesCommand) => {
    return this.accessFunction(command, FirebaseFunctionName.gainPrivilegesOverTenant)
  }

  dropPrivilegesOverTenant = async (command: UpdatePrivilegesCommand) => {
    const idToken = String(useAuthState().getAccessToken())

    return handlePromise<any, FirebaseError>(
      customHttpsCallable({
        functions: this.functions,
        functionName: FirebaseFunctionName.dropPrivilegesOverTenant,
        method: 'POST',
        tenantId: command.tenantId,
        token: idToken
      })(command)
    )
  }

  setActiveTenant = async (tenantId?: string) => {
    return this.accessFunction({ tenantId }, FirebaseFunctionName.setActiveTenant)
  }

  clearActiveTenant = async (tenantId?: string) => {
    return this.accessFunction({ tenantId }, FirebaseFunctionName.clearActiveTenant)
  }

}
