import PromiseUtils from '@/utilities/promise/PromiseUtils'
import client from '@/services/organization/OrganizationApi'
import { Organization, UpdateOrganizationCommand } from '@/generated/finsta-client-ts'

export const findOrganization = async (orgNo: string): Promise<Organization | undefined> => {
  const { data, error } = await PromiseUtils.handlePromise(client.findOrganization({ orgNo }).toPromise())

  if (error) {
    console.error('FindOrganizationError: ', error)
    return undefined
  }

  return data
}

export const updateOrganization = async (cmd: UpdateOrganizationCommand): Promise<Organization|undefined> => {
  const { data, error } = await PromiseUtils.handlePromise(
    client.updateOrganization({ updateOrganizationCommand: cmd }).toPromise()
  )

  if (error) {
    console.error('UpdateOrganizationError: ', error)
    return undefined
  }

  return data
}
