import { Configuration, Middleware, RequestArgs } from '@/generated/finsta-client-ts'
import useAuthState from '@/store/UseAuthState'

export default class PatronRequestInterceptor extends Configuration {
  // eslint-disable-next-line no-use-before-define
  private static config: PatronRequestInterceptor

  private constructor () {
    const middleware: Middleware[] = [{
      pre (request: RequestArgs): RequestArgs {
        const { getTenantId, getAccessToken } = useAuthState()

        const tenantId = getTenantId()
        const accessToken = getAccessToken()

        return {
          ...request,
          headers: {
            ...(request.headers ?? {}),
            ...(tenantId ? { OrganizationId: tenantId } : {}),
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {})
          }
        }
      }
    }]

    super({ middleware })
  }

  public static get Instance () {
    return PatronRequestInterceptor.config || (PatronRequestInterceptor.config = new this())
  }
}
