import FirebaseAuth from '@/plugins/firebase/auth/FirebaseAuth'
import { firebaseConfigStore } from '@/plugins/firebase/config/firebaseConfig.store'
import FirebaseFirestore from '@/plugins/firebase/firestore/Firestore'
import FirestoreWatch from '@/plugins/firebase/firestore/FirestoreWatch'
import FirebaseFunctions from '@/plugins/firebase/functions/FirebaseFunctions'
import { initializeApp } from 'firebase/app'
import { connectAuthEmulator, getAuth } from 'firebase/auth'
import { connectFirestoreEmulator, getFirestore } from 'firebase/firestore'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'

let firebaseAuth: FirebaseAuth
let firebaseFunctions: FirebaseFunctions
let firebaseFirestore: FirebaseFirestore

let firestoreWatch: FirestoreWatch

export const initializeFirebase = async () => {
  const app = initializeApp(firebaseConfigStore().firebaseConfig)

  const auth = getAuth(app)
  const functions = getFunctions(app)
  const firestore = getFirestore(app)

  if (firebaseConfigStore().useFirebaseEmulator) {
    connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true })
    connectFunctionsEmulator(functions, 'localhost', 5001)
    connectFirestoreEmulator(firestore, 'localhost', 8484)
  }

  firebaseFunctions = new FirebaseFunctions()
  firebaseFunctions.init()

  firebaseFirestore = new FirebaseFirestore()
  firebaseFirestore.init()

  firebaseAuth = new FirebaseAuth()
  firebaseAuth.init(firebaseFunctions)

  firestoreWatch = new FirestoreWatch()

  if (import.meta.hot) {
    import.meta.hot.data.firebaseFunctions = firebaseFunctions
    import.meta.hot.data.firebaseFirestore = firebaseFirestore
    import.meta.hot.data.firebaseAuth = firebaseAuth
  }
}

export const initFirestoreWatch = () => {
  firestoreWatch.init(firebaseFirestore)

  if (import.meta.hot) {
    import.meta.hot.data.firestoreWatch = firestoreWatch
  }
}

// if we are in a hot module replacement scenario
if (import.meta.hot) {

  if (import.meta.hot.data) {
    firebaseAuth = import.meta.hot.data.firebaseAuth
    firebaseFunctions = import.meta.hot.data.firebaseFunctions
    firebaseFirestore = import.meta.hot.data.firebaseFirestore
    firestoreWatch = import.meta.hot.data.firestoreWatch
  }

  import.meta.hot.accept()

  // This will be passed along to the next `import.meta.hot.data` if this module is ever hot-reloaded
  // TODO - 2023-06-12: this might be unnecessary
  import.meta.hot.dispose((data) => {
    /* eslint-disable no-param-reassign */
    data.firebaseAuth = firebaseAuth
    data.firebaseFunctions = firebaseFunctions
    data.firebaseFirestore = firebaseFirestore
    data.firestoreWatch = firestoreWatch
  })
}

export const useFirebaseAuth = () => firebaseAuth
export const useFirebaseFunctions = () => firebaseFunctions
export const useFirebaseFirestore = () => firebaseFirestore
export const useFirebaseWatch = () => firestoreWatch
