import URLResolver from '@/services/URLResolver'
import { Organization } from '@/generated/finsta-client-ts'
import { useFirebaseAuth, useFirebaseFunctions } from '@/plugins/firebase'
import { allFinancialStatementsRoute, onboardingStatusRoute, organizationsRoute, provisionStatusRoute, subscriptionStatusRoute } from '@/router/paths'
import usePatronService from '@/services/patron/PatronService'
import { ensureTenantActive } from '@/services/tenant/TenantService'
import useAuthState from '@/store/UseAuthState'
import { NavigationGuardWithThis } from 'vue-router'

const hasFinstaOnboarding = (organization?: Organization) => {
  if (!organization) {
    return false
  }

  return !!organization.establishedAt
}

export const allGuardsInOne: NavigationGuardWithThis<any> = async (to) => {
  const isAuthenticated: boolean = useAuthState().authenticated
  const requiresAuth: boolean = to.matched.some((record) => record.meta.requiresAuth)

  /** ALLOW ANONYMOUS CHECK */
  if (!requiresAuth) {
    if (!isAuthenticated) {
      return undefined
    } else {
      return organizationsRoute.route()
    }
  }

  /** REQUIRES AUTHENTICATION CHECK */
  if (requiresAuth && !isAuthenticated) {
    const ssoResult = await useFirebaseFunctions().hasSingleSignOnSession(true)
    if (ssoResult.data?.token) {
      const credentials = await useFirebaseAuth().signInWithCustomToken(ssoResult.data?.token)
      if (!credentials) {
        // TODO - 2023-08-21: return a redirect route object / component
        window.location.href = URLResolver.resolveSigninLocation()
      }
    } else {
      // TODO - 2023-08-21: return a redirect route object / component
      window.location.href = URLResolver.resolveSigninLocation()
    }
  }

  /** TENANT ID CHECK */
  const requiresTenantId = to.matched.some((record) => record.meta?.requiresTenant || false)
  const hasTenantId = !!useAuthState().getTenantId()
  if (!hasTenantId) {
    if (!requiresTenantId) {
      return undefined
    } else {

      /** TENANT IDENTITY CHECK */
      const nextTenant: string | undefined = to.params.tenantId?.toString()
      if (nextTenant && nextTenant !== 'undefined') {
        const isActiveTenant = await ensureTenantActive(nextTenant)
        if (!isActiveTenant) {
          return organizationsRoute.route()
        }
      } else {
        return organizationsRoute.route()
      }
    }
  }

  /** TENANT IDENTITY CHECK */
  const nextTenant: string | undefined = to.params.tenantId?.toString()
  if (nextTenant) {
    const isActiveTenant = await ensureTenantActive(nextTenant)
    if (!isActiveTenant) {
      return organizationsRoute.route()
    }
  }

  /** TENANT SUBSCRIPTION CHECK */
  const requiresActiveSubscription = to.matched.some((record) => record.meta?.requiresActiveSubscription || false)
  const hasSubscription = usePatronService().hasSubscription()
  if (!hasSubscription) {
    if (!requiresActiveSubscription) {
      return undefined
    } else {
      const tid = to.params.tenantId.toString()
      // const tid = useAuthState().getTenantId()
      return subscriptionStatusRoute.route({ tenantId: tid || '' })
    }
  }

  /** TENANT PROVISIONING CHECK */
  const requiresSuccessfulProvision = to.matched.some((record) => record.meta?.requiresSuccessfulProvision || false)
  const hasProvision = usePatronService().hasProvision()
  if (!hasProvision) {
    if (!requiresSuccessfulProvision) {
      return undefined
    } else {
      const tid = to.params.tenantId.toString()
      // const tid = useAuthState().getTenantId()
      return provisionStatusRoute.route({ tenantId: tid || '' })
    }
  }

  /** ONBOARDING CHECK */
  const requiresOnboarding = to.matched.some((record) => record.meta?.requiresOnboarding || false)
  const hasOnboarding = hasFinstaOnboarding(useAuthState().finstaOrg.value)
  if (!hasOnboarding) {
    if (!requiresOnboarding) {
      return undefined
    } else {
      const tid = to.params.tenantId.toString()
      // const tid = useAuthState().getTenantId()
      return onboardingStatusRoute.route({ tenantId: tid || '' })
    }
  }

  /** FINANCIAL STATEMENT CHECK */
  const params = to.params
  if ('uuid' in params) {
    const uuid = params.uuid
    if (uuid.length !== 8) {
      return allFinancialStatementsRoute.route({ tenantId: to.params.tenantId.toString() })
    }
  }

  return undefined
}
