import PatronRequestInterceptor from '@/services/configuration/PatronMiddleware'
import { resolvePatronApiPath } from '@/services/ServerBasePathResolver'
import { Configuration, PatronApi } from '@tritt/patron-client-ts'

const client = new PatronApi(new Configuration({
  basePath: resolvePatronApiPath(),
  middleware: PatronRequestInterceptor.Instance.middleware
}))

export default client
