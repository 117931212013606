import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { App, createApp } from 'vue'
import { ToastComponent } from '@/plugins/toast/types'

import ToastContainer from '@/plugins/toast/ToastContainer.vue'

export const toastApp = createApp(ToastContainer)

toastApp.component('font-awesome-icon', FontAwesomeIcon)

const toastContainer = toastApp.mount('#toast-container')

const ToastPlugin = {
  install (app: App, ...options: any[]) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$toast = toastContainer
  }
}

const toastManager = toastContainer as any as ToastComponent

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $toast: ToastComponent
  }
}

export default ToastPlugin

export const useToast = () => toastManager
