export default class ArrayUtils {

  /**
   *
   * @param a Array a
   * @param b Array b
   * @returns Returns elements of **a** that is included in **b**
   */
  static intersection = <T> (a: T[], b: T[]) => {
    return a.filter((x) => b.includes(x))
  }

  /**
   *
   * @param a Array a
   * @param b Array b
   * @returns Returns elements of **a** that is not included in **b** and elements of **b** that is not included in **a**
   */
  static symmetricDifference = <T> (a: T[], b: T[]) => {
    return a
      .filter((x) => !b.includes(x))
      .concat(b.filter((x) => !a.includes(x)))
  }

  /**
   * Join an array with different separators.
   *
   * @returns string
   */
  static smartJoin = ((entries: any[], separator: string = ', ', lastSeparator: string = ' and '): string => {

    if (entries.length <= 1) {
      return entries.join()
    }
    const most: any[] = entries.slice(0, -1)
    const last: any = entries.slice(-1)[0]

    return most.join(separator) + lastSeparator + last
  })

}
