import { useFirebaseAuth } from '@/plugins/firebase'
import { ref } from 'vue'

// TODO@kristian - 2023-06-12: Might need HMR fix

const loggedIn = ref(false)

async function initRequest(requestMethod: string) {
  return useFirebaseAuth().getUserIdToken()?.then(token => {
    return {
      method: requestMethod,
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  })
}

async function login() {

  const response = await fetch('/idporten/login', await initRequest('GET'))

  if (response.status === 200) {
    window.location.replace(await response.text())
  } else {
    console.log(`Got unexpected status [${response.status}] when fetching ID-porten login url`)
  }
}

async function logout() {
  const tokenExists = await checkForExistingToken()

  if (!tokenExists) {
    return
  }

  const url = '/idporten/logout'
  const response = await fetch(url, await initRequest('GET'))
  window.location.replace(await response.text())
}

async function checkForExistingToken() {
  const url = '/idporten/token'
  const response = await fetch(url, await initRequest('HEAD'))
  loggedIn.value = response.status === 200
  return loggedIn.value
}

const useIdPortenService = () => {
  return {
    loggedIn,
    login,
    logout,
    checkForExistingToken
  }
}

export default useIdPortenService
