export const resolvePatronApiPath = () => {
  const { host, protocol } = window.location

  return resolveApiPath(host, protocol) + '/patron'
}

export const resolveFinstaApiPath = () => {
  const { host, protocol } = window.location

  return resolveApiPath(host, protocol) + '/finsta'
}

export const resolveConfigApiPath = () => {
  const { host, protocol } = window.location

  return resolveApiPath(host, protocol) + '/config/firebase/finsta'
}

export const resolveApiPath = (host: string, protocol: string) => {
  const match = host.match(/(xn--rsoppgjr-8za1r|årsoppgjør|arsoppgjor|finsta|app).(.*)/)

  if (match != null && match.length >= 3) {
    if (match[2].includes('conta')) {
      match[2] = match[2].replace('conta', 'tritt')
    }
    return protocol + '//api.' + match[2]
  }

  console.error('ServerBasePathResolver error: You are using a hostname that will not work!')
  return '/api'
}
