import { useFirebaseAuth, useFirebaseFirestore } from '@/plugins/firebase'
import FirebaseFirestore from '@/plugins/firebase/firestore/Firestore'
import { ApplicationLocale, FirestoreOrg, FirestoreOrgField, FirestoreUser, FirestoreUserField, TenantRole } from '@/plugins/firebase/types'
import useAuthState from '@/store/UseAuthState'
import ArrayUtils from '@/utilities/ArrayUtils'
import RoleUtils from '@/utilities/authorization/RoleUtils'
import EnumHelper from '@/utilities/EnumHelper'
import { DocumentData, DocumentSnapshot, onSnapshot, query } from 'firebase/firestore'
import { ref, watch } from 'vue'

const parseRoles = (data: TenantRole[]) => {
  const result: TenantRole[] = []
  const entries = EnumHelper.getNamesAndValues(TenantRole)
  data.forEach((e) => {
    const item = entries.find((en) => en.value === e)
    if (item) {
      result.push(e)
    }
  })
  return result
}

const readOrganization = (snapshot?: DocumentSnapshot<DocumentData>) => {
  if (snapshot?.exists) {
    const org: FirestoreOrg = {
      id: snapshot.id,
      organizationId: snapshot.get(FirestoreOrgField.organizationId),
      organizationName: snapshot.get(FirestoreOrgField.organizationName),
      organizationNumber: snapshot.get(FirestoreOrgField.organizationNumber),
      protected: snapshot.get(FirestoreOrgField.protected),
      roles: parseRoles(snapshot.get(FirestoreOrgField.roles))
    }
    return org
  }
  return undefined
}

export const parseApplicationLocale = (data: any) => {
  if (typeof data === 'string') {
    const locale = data as ApplicationLocale
    if (EnumHelper.getObjValues(ApplicationLocale).includes(locale)) {
      return locale
    }
  }
  return ApplicationLocale.norwegianBokmal
}

const readUser = (firestoreUser?: DocumentSnapshot<DocumentData>) => {
  if (firestoreUser?.exists()) {
    const u: FirestoreUser = {
      id: firestoreUser.id,
      displayName: firestoreUser.get(FirestoreUserField.displayName),
      phoneNumber: firestoreUser.get(FirestoreUserField.phoneNumber),
      email: firestoreUser.get(FirestoreUserField.email),
      language: parseApplicationLocale(firestoreUser.get(FirestoreUserField.language)),
      settings: firestoreUser.get(FirestoreUserField.settings),
      roles: RoleUtils.filterUserRoles(firestoreUser.get(FirestoreUserField.roles))
    }
    return u
  }
  return undefined
}

const hasOrgChanges = (oldOrgs: FirestoreOrg[], newOrgs: FirestoreOrg[]) => {
  if (oldOrgs.length !== newOrgs.length) {
    return true
  }

  let hasChanges = false

  newOrgs.forEach((newOrg: FirestoreOrg) => {
    const oldRoles: TenantRole[] = oldOrgs.find((oldOrg: FirestoreOrg) => oldOrg.organizationId === newOrg.organizationId)?.roles || []
    if (ArrayUtils.symmetricDifference(oldRoles, newOrg.roles).length) {
      hasChanges = true
    }
  })

  return hasChanges
}

export default class FirestoreWatch {
  firestore!: FirebaseFirestore

  userOrganizations = ref<FirestoreOrg[]>([])

  user = ref<FirestoreUser>()

  clearUserOrganizationsSubscription = () => {}

  clearUserSubscription = () => {}

  init (fs: FirebaseFirestore) {
    this.firestore = fs

    const { uid } = useAuthState()

    watch(() => uid.value, (newUid) => {
      if (!newUid) {
        return
      }

      this.watchUserOrganizations(
        newUid,
        async (orgs) => {
          if (hasOrgChanges(this.userOrganizations.value, orgs || [])) {
            // If a change to user organizations is detected we refresh the token because roles may have been updated
            setTimeout(async () => {
              await useFirebaseAuth().refreshToken()
            }, 3000) // wait to ensure that claims have been updated
          }

          this.userOrganizations.value = orgs || []
        }
      )

      // TODO - 2023-05-22: Start using user for something? (language, roles, etc)
      this.watchUser(
        newUid,
        async (u) => {
          this.user.value = u
        }
      )

    }, { immediate: true })
  }

  public fetchUserOrganizations = async (uid: string) => {
    const orgs = await useFirebaseFirestore().getOrgs(uid)
    this.userOrganizations.value = orgs
  }

  private watchUserOrganizations = (uid: string, onUpdate: (orgs: FirestoreOrg[] | undefined) => void) => {
    this.clearUserOrganizationsSubscription()

    const collectionRef = this.firestore.getOrgCollection(uid)
    const collectionQuery = query(collectionRef)

    const subscription = onSnapshot(
      collectionQuery,

      (doc) => {
        const orgs: FirestoreOrg[] = []
        doc.docs.forEach((d) => {
          const org = readOrganization(d)
          if (org) {
            orgs.push(org)
          }
        })
        onUpdate(orgs)
      },

      () => {
        onUpdate(undefined)
      }
    )

    this.clearUserOrganizationsSubscription = () => {
      subscription()
      onUpdate(undefined)
    }
  }

  private watchUser = (uid: string, onUpdate: (user?: FirestoreUser) => void) => {
    this.clearUserSubscription()
    const subscription = onSnapshot(
      this.firestore.getUserDoc(uid),
      (doc) => onUpdate(readUser(doc)),
      this.clearUserSubscription
    )
    this.clearUserSubscription = () => {
      subscription()
      onUpdate(undefined)
    }
  }

}
